import React, { useCallback } from "react";
import { Image, Modal, Row, Col } from "react-bootstrap";
import styles from "./NavigationModal.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setThreeSixtyData } from "../../store/slices/threeSixtyViewerSlice";
import {
  setNavigationState,
  unhideAreas,
} from "../../store/slices/navigationSlice";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { OutletContextDefinition } from "../../types";

interface IProps {
  show: boolean;
  logo: string;
  viewerId: string;
}

const areasToHide = ["Exterior", "Living Area"];

const NavigationModal: React.FC<IProps> = ({ show, logo, viewerId }) => {
  const { areas, audioManager } = useOutletContext<OutletContextDefinition>();
  const progress = useAppSelector((state) => state.progress);
  const { hideAreas } = useAppSelector((state) => state.navigation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { executeFunctionNow } = audioManager;

  const areaClicked = useCallback(
    (areaId) => {
      const tmpFn = () => {
        if (!areas) {
          return;
        }
        const destinationArea = areas.find((area) => area._id === areaId);
        if (!destinationArea) {
          return;
        }
        dispatch(setNavigationState(false));
        dispatch(setThreeSixtyData(destinationArea));
        setTimeout(() => {
          dispatch(unhideAreas());
        }, 100);
        if (location.pathname !== `/${viewerId}`) {
          navigate(`/${viewerId}`);
        }
      };
      executeFunctionNow(tmpFn);
    },
    [dispatch, navigate, executeFunctionNow, areas, location, viewerId]
  );

  if (!areas) {
    return <p>Please wait...</p>;
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(setNavigationState(true));
      }}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
    >
      <Modal.Body>
        <Row className="my-3 my-lg-5">
          <Col
            xs={{ offset: 3, span: 6 }}
            md={{ offset: 5, span: 2 }}
            className="d-flex justify-content-center"
          >
            <Image src={logo} className={styles.logo} fluid />
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} className="d-flex justify-content-center">
            <h3>Please select an area</h3>
          </Col>
        </Row>
        <Row>
          {areas
            .filter((area) =>
              hideAreas ? !areasToHide.includes(area.name) : true
            )
            .filter((area) => !area.hide_in_navigation)
            .map((area, index) => (
              <Col
                key={index}
                sm={4}
                className={styles.blockContainer}
                onClick={() => {
                  areaClicked(area._id);
                }}
              >
                <div className={styles.imageContainer}>
                  <Image
                    src={`${
                      area.thumbnail ?? area.spherical_image_url + "nz.jpg"
                    }`}
                    fluid
                  />
                </div>
                <div className={styles.textContainer}>
                  <p>{area.name}</p>
                </div>
                {progress && (
                  <div className={styles.statusContainer}>
                    {progress.areasProgress.find((a) => a.area === area._id)
                      ?.is_area_completed && <p>COMPLETED</p>}
                  </div>
                )}
              </Col>
            ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default NavigationModal;
