import React, { useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { OutletContextDefinition } from "../../types";
import styles from "./Video.module.css";

interface IProps {
  videoUrl: string;
  onVideoEnded?: () => void;
}

const Video: React.FC<IProps> = ({ videoUrl, onVideoEnded }) => {
  const { audioManager } = useOutletContext<OutletContextDefinition>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const dispatch = useAppDispatch();
  const { isAudioElementMuted } = audioManager;
  useEffect(() => {
    const videoPlayer = videoRef.current;
    const playVideo = () => {
      if (videoPlayer) {
        if (isAudioElementMuted) {
          videoPlayer.muted = true;
        }
        videoPlayer.play();
        if (videoPlayer.requestFullscreen) {
          videoPlayer.requestFullscreen();
        }
      }
    };
    if (videoPlayer) {
      videoPlayer.load();
      videoPlayer.addEventListener("canplay", playVideo);
      if (onVideoEnded) {
        videoPlayer.addEventListener("ended", onVideoEnded);
      }
    }
    return () => {
      if (videoPlayer) {
        videoPlayer.removeEventListener("canplay", playVideo);
        if (onVideoEnded) {
          videoPlayer.removeEventListener("ended", onVideoEnded);
        }
      }
    };
  }, [videoUrl, isAudioElementMuted, onVideoEnded, dispatch]);

  return (
    <div className={styles.videoContainer}>
      <video ref={videoRef} controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </div>
  );
};

export default Video;
