import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ParentComponent from "./ParentComponent";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PageNotFound from "./pages/Page404";
import { Provider } from "react-redux";
import { store } from "./store";
import { Toaster } from "react-hot-toast";
import Profile from "./pages/Profile";
import ProtectedComponent from "./ProtectedComponent";
import BaseComponent from "./BaseComponent";
import "hello-react-sdk-v2/dist/index.css";


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Toaster />
        <Router>
          <Routes>
            <Route path="/:viewerId" element={<ParentComponent />}>
              <Route path={""} element={<ProtectedComponent />}>
                <Route path={""} element={<Home />} />
                <Route path={"profile"} element={<Profile />} />
              </Route>
              <Route path={"login"} element={<Login />} />
            </Route>
            <Route path="/" element={<BaseComponent />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
