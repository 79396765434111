import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AreaProgressDefinition,
  AssessmentStatus,
  LearningStatus,
} from "../../api";

export type ReduxUserProgressDefinition = {
  is_area_completed?: boolean;
} & AreaProgressDefinition;

interface SliceType {
  userProgressId: string;
  areasProgress: ReduxUserProgressDefinition[];
}

const initialState: SliceType = {
  userProgressId: "",
  areasProgress: [],
};

interface Payload1 {
  areaId: string;
  hotspotId: string;
  status: LearningStatus;
}

interface Payload2 {
  areaId: string;
  hotspotId: string;
  status: AssessmentStatus;
}

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setUserProgressId: (state, { payload }: PayloadAction<string>) => {
      state.userProgressId = payload;
    },
    setProgress: (
      state,
      { payload }: PayloadAction<ReduxUserProgressDefinition[]>
    ) => {
      state.areasProgress = payload;
    },
    setLearningStatus: (state, { payload }: PayloadAction<Payload1>) => {
      const areaProgressIndex = state.areasProgress.findIndex(
        (ap) => ap.area === payload.areaId
      );
      const areaProgress = state.areasProgress[areaProgressIndex];
      const learningIndex = areaProgress?.learning_progress.findIndex(
        (lp) => lp.hotspot === payload.hotspotId
      );
      if (learningIndex >= 0) {
        state.areasProgress[areaProgressIndex].learning_progress[
          learningIndex
        ].status = payload.status;
      }
    },
    setAssessmentStatus: (state, { payload }: PayloadAction<Payload2>) => {
      
      const areaProgressIndex = state.areasProgress.findIndex(
        (ap) => ap.area === payload.areaId
      );
      const areaProgress = state.areasProgress[areaProgressIndex];
      const assessmentIndex = areaProgress?.assessment_progress.findIndex(
        (ap) => ap.hotspot === payload.hotspotId
      );
      if (assessmentIndex >= 0) {
        state.areasProgress[areaProgressIndex].assessment_progress[
          assessmentIndex
        ].status = payload.status;
      }
    },
    setAreaAsComplete: (state, { payload }: PayloadAction<string>) => {
      const areaProgressIndex = state.areasProgress.findIndex(
        (ap) => ap.area === payload
      );
      if (areaProgressIndex) {
        state.areasProgress[areaProgressIndex].is_area_completed = true;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProgress,
  setLearningStatus,
  setAssessmentStatus,
  setAreaAsComplete,
  setUserProgressId,
} = progressSlice.actions;
export default progressSlice.reducer;
