import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SliceType {
  shouldShowNavigation: boolean;
  nextArea?: string;
  hideAreas: boolean;
  displayType: "AREA" | "POPUP";
}

const initialState: SliceType = {
  shouldShowNavigation: false,
  nextArea: undefined,
  hideAreas: false,
  displayType: "AREA",
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    showNavigationModal: (state) => {
      state.shouldShowNavigation = true;
    },
    hideNavigationModal: (state) => {
      state.shouldShowNavigation = false;
    },
    setNavigationState: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldShowNavigation = payload;
    },
    setNextArea: (state, { payload }: PayloadAction<string>) => {
      state.nextArea = payload;
    },
    hideAreas: (state) => {
      state.hideAreas = true;
    },
    unhideAreas: (state) => {
      state.hideAreas = false;
    },
    setDisplayType: (state, { payload }: PayloadAction<"AREA" | "POPUP">) => {
      state.displayType = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showNavigationModal,
  hideNavigationModal,
  setNavigationState,
  setNextArea,
  hideAreas,
  unhideAreas,
  setDisplayType,
} = navigationSlice.actions;
export default navigationSlice.reducer;
