import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import FullScreenError from "./components/FullScreenError";
import LandingScreenAnim from "./components/LandingScreenAnim";
import { useAreas } from "./queries/useLMS";
import { useProgress } from "./queries/useProgress";
import { OutletContextDefinition } from "./types";

const ProtectedComponent = () => {
  const { viewerData, user, sessionId, audioManager } =
    useOutletContext<OutletContextDefinition>();
  const {
    data: areas,
    isLoading,
    isError,
    error,
  } = useAreas(user ? viewerData._id : "");
  const { data: progress, refetch } = useProgress(
    viewerData._id,
    user?.account_id ?? "",
    areas ?? []
  );

  if (!user) {
    return <Navigate to={"login"} replace />;
  }

  if (isError) {
    return (
      <FullScreenError
        msg={
          error && typeof error === "object"
            ? error.toString()
            : "There was an error"
        }
      />
    );
  }

  if (isLoading || !areas || !progress) {
    return <LandingScreenAnim />;
  }

  return (
    <Outlet
      context={{
        viewerData: viewerData,
        user: user,
        areas: areas,
        progress: progress,
        refetchProgress: refetch,
        sessionId: sessionId,
        audioManager: audioManager,
      }}
    />
  );
};

export default ProtectedComponent;
