import React from "react";

interface IProps {
  img: string;
}

const SphericalLoading: React.FC<IProps> = ({ img }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>
  );
};

export default SphericalLoading;
