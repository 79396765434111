import { QuestionDefinition } from "../../api";

export type ModalDisplayTypes =
  | "PRODUCT"
  | "LEARNING_COMPLETE"
  | "MODULE_COMPLETE"
  | "MODULE"
  | "VIDEO";

export interface ModalData {
  displayType: ModalDisplayTypes;
  dataType: "PRODUCT" | "VIDEO";
  dataId: string;
  questions?: QuestionDefinition[];
}

export interface ModalAction {
  type:
    | "SET_PRODUCT"
    | "SET_VIDEO"
    | "SET_MODULE"
    | "SET_DISPLAY_TYPE"
    | "SET_DATA"
    | "RESET";
  payload: {
    dataId?: string;
    dataType?: "PRODUCT" | "VIDEO";
    displayType?: ModalDisplayTypes;
    questions?: QuestionDefinition[];
  };
}

export const initialData: ModalData = {
  displayType: "PRODUCT",
  dataType: "PRODUCT",
  dataId: "",
};

export const dataReducer = (
  state: ModalData,
  action: ModalAction
): ModalData => {
  switch (action.type) {
    case "SET_PRODUCT":
      if (action.payload.dataId) {
        return {
          ...state,
          dataType: "PRODUCT",
          dataId: action.payload.dataId,
        };
      }
      break;
    case "SET_VIDEO":
      if (action.payload.dataId) {
        return {
          ...state,
          dataType: "VIDEO",
          dataId: action.payload.dataId,
        };
      }
      break;
    case "SET_DATA":
      if (action.payload.dataType && action.payload.dataId) {
        return {
          ...state,
          dataType: action.payload.dataType,
          dataId: action.payload.dataId,
        };
      }
      break;
    case "SET_MODULE":
      return {
        ...state,
        questions: action.payload.questions,
      };
    case "SET_DISPLAY_TYPE":
      if (action.payload.displayType) {
        return {
          ...state,
          displayType: action.payload.displayType,
        };
      }
      break;
    case "RESET":
      return {
        ...initialData,
      };
    default:
      console.error("Error in switch case");
  }
  return { ...state };
};
