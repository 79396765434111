import { useQuery } from "react-query";
import { AreaDefinition, getAreas } from "../api";

export const useAreas = (viewerId: string) => {
  const queryKeys = [viewerId];
  return useQuery<AreaDefinition[]>(
    queryKeys,
    async () => {
      const areas = await getAreas(viewerId);
      return areas._items;
    },
    {
      enabled: viewerId.length > 0,
    }
  );
};
