import React, { useMemo } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { AreaDefinition, SubAreaDefinition } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setThreeSixtyData } from "../../store/slices/threeSixtyViewerSlice";
import { OutletContextDefinition } from "../../types";
import styles from "./AreaPopup.module.css";

interface IProps {
  areas: AreaDefinition[];
  popupData: SubAreaDefinition[];
  backgroundImage: string;
}

interface SubAreaCardProps {
  index: number;
  imageUrl: string;
  buttonText: string;
  destinationAreaId: string;
  areaName: string;
}

const blackImageURL =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/800px-A_black_image.jpg?20201103073518";

const AreaPopup: React.FC<IProps> = ({ areas, popupData, backgroundImage }) => {
  const { audioManager } = useOutletContext<OutletContextDefinition>();
  const progress = useAppSelector((state) => state.progress);
  const dispatch = useAppDispatch();
  const { executeFunctionNow } = audioManager;
  const handleButtonClick = (areaId: string) => {
    const tempFn = () => {
      const destinationArea = areas.find((area) => area._id === areaId);
      if (destinationArea) {
        dispatch(setThreeSixtyData(destinationArea));
      }
    };
    executeFunctionNow(tempFn);
  };

  const subAreasStatusList: string[] = useMemo(() => {
    const statusList = popupData.map((pop) => {
      const isDestinationAreaComplete =
        progress &&
        progress.areasProgress &&
        progress.areasProgress.find((p) => p.area === pop.area)
          ?.is_area_completed;
      if (isDestinationAreaComplete) {
        return "COMPLETE";
      }
      return "INCOMPLETE";
    });
    return statusList;
  }, [popupData, progress]);

  const subAreasEnableList: string[] = useMemo(() => {
    let incompleteArea: number | undefined = undefined;
    for (let i = 0; i < subAreasStatusList.length; i++) {
      if (subAreasStatusList[i] === "INCOMPLETE") {
        incompleteArea = i;
        break;
      }
    }
    if (typeof incompleteArea === "number") {
      return subAreasStatusList.map((status, index) =>
        index === incompleteArea ? "ENABLE" : "DISABLE"
      );
    }
    return subAreasStatusList.map(() => "ENABLE");
  }, [subAreasStatusList]);

  const SubAreaCard: React.FC<SubAreaCardProps> = ({
    index,
    imageUrl,
    buttonText,
    destinationAreaId,
    areaName,
  }) => {
    const shouldDisable = subAreasEnableList[index] === "DISABLE";
    return (
      <div className={shouldDisable ? styles.disabled : ""}>
        <div className={styles.imageContainer}>
          <Image src={imageUrl} fluid />
          {subAreasStatusList[index] === "COMPLETE" && (
            <div className={styles.statusContainer}>
              <p>COMPLETED</p>
            </div>
          )}
        </div>
        <h4 className={styles.areaName}>{areaName}</h4>
        <Button
          variant="outline-dark"
          onClick={() => {
            handleButtonClick(destinationAreaId);
          }}
          className={styles.button}
          disabled={shouldDisable}
        >
          {buttonText}
        </Button>
        {/* {shouldDisable && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "red",
              top: 0,
              opacity: 0.1,
            }}
          />
        )} */}
      </div>
    );
  };
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${backgroundImage}"), url("${blackImageURL}")`,
      }}
    >
      <Row className={styles.dataContainer}>
        {popupData.map((pop, index) => (
          <Col xs={6} md={6} key={index} className={styles.element}>
            <SubAreaCard
              index={index}
              imageUrl={pop.image}
              areaName={pop.name}
              buttonText={pop.button_text}
              destinationAreaId={pop.area}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AreaPopup;
