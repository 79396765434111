import React from "react";
import { Form } from "react-bootstrap";
import styles from "./Question.module.css";
interface IProps {
  questionNumber: number;
  question: string;
  options: string[];
  setAnswer: (answer: number) => void;
}

const Question: React.FC<IProps> = ({
  questionNumber,
  question,
  options,
  setAnswer,
}) => {
  const onOptionChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAnswer(parseInt(e.currentTarget.value));
  };
  return (
    <Form>
      <p className={styles.question}>
        <span className="me-2">{questionNumber}. </span>
        {question}
      </p>
      <div>
        {options.map((option, index) => (
          <Form.Check
            key={`${questionNumber} + ${index}`}
            type="radio"
            name="question"
            label={option}
            id={`${option}-${index}`}
            onChange={onOptionChange}
            value={index}
            className={styles.radio}
          />
        ))}
      </div>
    </Form>
  );
};

export default Question;
