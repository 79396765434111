import { useCallback, useState } from "react";
import { useNavigate, Navigate } from "react-router";
import toast from "react-hot-toast";
import { OutletContextDefinition, User } from "../../types";
import "react-phone-input-2/lib/style.css";
import styles from "./Login.module.css";
import OtpInputComponent from "../../components/OtpInput/OtpInput";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import { saveUserToLocalStorage } from "../../utils/localstorage";
import PhoneInputComponent from "../../components/PhoneInput/PhoneInput";
import { Col, Row, Button } from "react-bootstrap";
import {
  SendOtpDataType,
  VerifyOtpDataType,
  sendOtp,
  verifyOtp,
  getApplicationRoles,
} from "../../api";
import { useOutletContext } from "react-router-dom";
import placeholderLogo from "../../assets/images/brand_logo.svg";
import { useTriggerEvent } from "../../queries/useEvents";
import MicrosoftLogin from "../../components/MicrosoftLogin";

const loginAudio =
  "https://content.helloviewer.io/viewer/configurations/media/c0896491-e834-4a3b-83f6-c41ff95eb427/login_audio.mp3";

const Login: React.FC = () => {
  const {
    viewerData,
    user: isAlreadyLoggedIn,
    sessionId,
  } = useOutletContext<OutletContextDefinition>();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginType, setLoginType] = useState("email");
  const [response, setResponse] = useState<{ requestId: string } | undefined>();
  const [otp, setOtp] = useState("");
  const [generatingOtp, setGeneratingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  // const [showLoginForm, setShowLoginForm] = useState(false);
  const showLoginForm = false;
  const navigate = useNavigate();
  const { triggerEvent } = useTriggerEvent(viewerData, undefined, sessionId);
  const { mutate } = triggerEvent;

  const onLogin = useCallback(
    (user: User) => {
      mutate({
        event: "user_logged_in",
        account_id: user.account_id,
        account_session_id: user.session_id,
      });
      saveUserToLocalStorage(viewerData._id, user);
      navigate(`/${viewerData._id}`);
    },
    [navigate, viewerData._id, mutate]
  );

  const logo =
    viewerData?.brand_details?.logo && viewerData.brand_details.logo.length > 0
      ? viewerData?.brand_details?.logo
      : placeholderLogo;

  const onSendOtp = async () => {
    let data: SendOtpDataType;
    if (
      loginType === "phone" &&
      !phoneNumber.match(/([+]?\d[.91][.-\s]?)?(\d{3}?){2}\d{4}/)
    ) {
      toast.error("Please enter a valid phone number");
      return;
    }
    if (loginType === "phone") {
      const updatedPhonenumber = `+${phoneNumber}`;
      data = {
        phone_number: updatedPhonenumber,
        app_source: "web",
      };
    } else {
      data = {
        email: email,
        app_source: "web",
      };
    }
    setGeneratingOtp(true);
    try {
      const requestId = await sendOtp({ data });
      setResponse({ requestId: requestId });
      setGeneratingOtp(false);
    } catch (err) {
      toast.error("User not found");
      setGeneratingOtp(false);
    }
  };
  const onVerifyOtp = async () => {
    if (!response?.requestId) {
      return;
    }
    const data: VerifyOtpDataType = {
      request_id: response.requestId,
      otp: parseInt(otp),
    };
    setVerifyingOtp(true);
    try {
      const userDetails = await verifyOtp({ data: data });
      setVerifyingOtp(false);
      setResponse(undefined);
      setOtp("");
      await verifyUserRole(userDetails);
    } catch (err) {
      toast.error("Invalid OTP");
      setVerifyingOtp(false);
    }
  };

  const verifyUserRole = async (userDetails: User) => {
    try {
      const userRole = await getApplicationRoles(
        userDetails.account_id,
        viewerData.application
      );
      if (userRole.viewer && userRole.viewer !== viewerData._id) {
        throw new Error("No access");
      }
      onLogin(userDetails);
    } catch (err) {
      toast.error("You don't have access to this viewer");
      setVerifyingOtp(false);
      console.error("User doesn't have access to viewer");
    }
  };

  if (isAlreadyLoggedIn) {
    return <Navigate to={"../"} replace />;
  }

  return (
    <div style={{ width: "100%", height: "100%", display: "flex" }}>
      <div className={`${styles.loginBg}`}></div>
      <Row className={`${styles.formContainer}`}>
        <Col
          xs={12}
          md={{ span: 6, offset: 3 }}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={logo} alt="" className={styles.logo} />
          <AudioPlayer audio={loginAudio} />
        </Col>
        {response?.requestId && (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onVerifyOtp();
              }}
            >
              <Col xs={12} className={styles.flexCol}>
                <h2 className={styles.loginTitle}>
                  OTP sent to your
                  {loginType === "phone" ? ` phone` : ` email`}
                </h2>
              </Col>
              <OtpInputComponent otp={otp} setOtp={setOtp} />
              <Col xs={12} className={`mt-3 ${styles.flexCol}`}>
                <h5
                  className={`${styles.cursorPointer}`}
                  onClick={() => onSendOtp()}
                >
                  {generatingOtp ? "Generating OTP..." : "Resend OTP"}
                </h5>
              </Col>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                className={styles.flexCol}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onVerifyOtp();
                  }}
                  disabled={generatingOtp}
                  variant="light"
                  className={styles.loginButton}
                >
                  {" "}
                  {verifyingOtp ? "Verifying OTP..." : "Login"}
                </Button>
              </Col>
              <Col xs={12} className={styles.flexCol}>
                <h5
                  className={`mt-2 ${styles.cursorPointer}`}
                  onClick={() => setResponse(undefined)}
                >
                  Use different{" "}
                  {loginType === "email" ? "email address" : "phone number"}
                </h5>
              </Col>
            </form>
          </>
        )}
        {!response?.requestId && (
          <>
            {!showLoginForm && (
              <>
                <Col xs={12}>
                  <h4 className={`h2 text-center ${styles.title}`}>Welcome</h4>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 8, offset: 2 }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {/* <Button
                    variant="light"
                    className={styles.loginButton}
                    onClick={() => setShowLoginForm(true)}
                  >
                    Login
                  </Button> */}

                  <MicrosoftLogin onLogin={verifyUserRole} />
                </Col>
              </>
            )}
            {showLoginForm && (
              <>
                <Col xs={12} className={styles.flexCol}>
                  <h2 className={styles.loginTitle}>Log In to access</h2>
                </Col>
                <Col xs={12} className={styles.loginOptionsContainer}>
                  <h4
                    className={`${styles.loginOption} ${
                      loginType === "phone" && styles.underlined
                    }`}
                    onClick={() => setLoginType("phone")}
                  >
                    Phone Number
                  </h4>
                  <h4
                    className={`${styles.loginOption} ${
                      loginType === "email" && styles.underlined
                    }`}
                    onClick={() => setLoginType("email")}
                  >
                    Email Address
                  </h4>
                </Col>
              </>
            )}
            <Col xs={12}>
              {showLoginForm && (
                <>
                  <div>
                    {loginType === "phone" ? (
                      <form
                        onSubmit={() => onSendOtp()}
                        style={{ height: "max-content" }}
                        className="d-flex flex-column justify-content-between align-items-center container-fluid px-0 mt-2 mt-md-4"
                      >
                        <div className="col-12 col-lg-9">
                          <PhoneInputComponent
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                          />
                        </div>
                        <div className="col-12 col-lg-9">
                          <Button
                            onClick={() => onSendOtp()}
                            disabled={generatingOtp}
                            variant="light"
                            className={styles.loginButton}
                          >
                            {generatingOtp ? "Generating OTP..." : "Get OTP"}
                          </Button>
                        </div>
                      </form>
                    ) : (
                      <form
                        onSubmit={() => onSendOtp()}
                        style={{ height: "max-content" }}
                        className="d-flex flex-column justify-content-between align-items-center container-fluid px-0 mt-2 mt-md-4"
                      >
                        <input
                          className={`col-12 col-lg-9 border-0 h-12 ${styles.inputField} py-2 text-center`}
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email"
                        />
                        <div className="col-12 col-lg-9">
                          <Button
                            onClick={() => onSendOtp()}
                            disabled={generatingOtp}
                            variant="light"
                            className={styles.loginButton}
                          >
                            {generatingOtp ? "Generating OTP..." : "Get OTP"}
                          </Button>{" "}
                        </div>
                      </form>
                    )}
                  </div>
                  <Col xs={12} className="mt-4">
                    <MicrosoftLogin onLogin={verifyUserRole} />
                  </Col>
                </>
              )}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Login;
