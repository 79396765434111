import React from "react";
import { OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import styles from "./MicrosoftLogin.module.css";
import { Button, Image } from "react-bootstrap";
import microsoftLogo from "../../assets/images/microsoft-logo.png";
import { OutletContextDefinition, User } from "../../types";
import { verifyFirebaseToken } from "../../api";
import { useOutletContext } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

interface IProps {
  onLogin: (userDetails: User) => void;
}

const provider = new OAuthProvider("microsoft.com");
const auth = getAuth();

const MicrosoftLogin: React.FC<IProps> = ({ onLogin }) => {
  const { viewerData } = useOutletContext<OutletContextDefinition>();

  const webLogin = async () => {
    const result = await signInWithPopup(auth, provider);
    const credential = OAuthProvider.credentialFromResult(result);
    if (!credential) {
      throw new Error("Microsoft login error");
    }
    const id = await result.user.getIdToken();
    return id;
  };

  const appLogin = async () => {
    const result = await FirebaseAuthentication.signInWithMicrosoft();
    if(result.user){
      const tokenObj = await FirebaseAuthentication.getIdToken();
      return tokenObj.token;  
    }
  };

  const onButtonClick = async () => {
    try {
      const platform = Capacitor.getPlatform();
      let userId: string | undefined;
      if (platform === "android") {
        userId = await appLogin();
      } else {
        userId = await webLogin();
      }

      if (userId) {
        const userDetails = await verifyFirebaseToken(
          userId,
          viewerData.application,
          viewerData._id
        );
        onLogin(userDetails);
      } else {
        alert("login Error");
      }
    } catch (err) {
      alert("Error while login with microsoft");
    }
  };

  return (
    <div className={styles.container}>
      <Button
        onClick={onButtonClick}
        variant="outline-dark"
        style={{ borderRadius: 0 }}
        className={styles.button}
      >
        <Image src={microsoftLogo} className={styles.logo} />
        Sign in with Microsoft
      </Button>
    </div>
  );
};

export default MicrosoftLogin;
