import React from "react";
import { Col } from "react-bootstrap";
import OtpInput from "react-otp-input";

const OtpInputComponent = ({
  otp,
  setOtp,
}: {
  otp: string;
  setOtp: (otp: string) => void;
}) => {
  return (
    <Col>
      <OtpInput
        containerStyle="d-flex justify-content-center"
        numInputs={4}
        inputStyle="h2 inputStyle"
        separator={<span className="h2 px-2"></span>}
        value={otp}
        onChange={(otp: string) => setOtp(otp)}
        isInputNum={true}
      />
    </Col>
  );
};

export default OtpInputComponent;
