import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AreaDefinition } from "../../api";

interface SliceType {
  areaData: AreaDefinition | null;
}

const initialState: SliceType = {
  areaData: null,
};

export const threeSixtyViewerSlice = createSlice({
  name: "threeSixtyViewer",
  initialState,
  reducers: {
    setThreeSixtyData: (state, action: PayloadAction<AreaDefinition>) => {
      state.areaData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setThreeSixtyData } = threeSixtyViewerSlice.actions;

export default threeSixtyViewerSlice.reducer;