import React, { useCallback, useRef } from "react";
import animationVideoExterior from "../../assets/videos/animation_2.mp4";
import animationVideoLiving from "../../assets/videos/animation_3.mp4";
import animationVideoHighway from "../../assets/videos/highwayToFarm.mp4";
import livingVideoPoster from "../../assets/images/video_posters/animation_3_poster.jpg";
import exteriorVideoPoster from "../../assets/images/video_posters/animation_2_poster.jpg";
import farmVideoPoster from "../../assets/images/video_posters/animation_4_poster.jpg";
import styles from "./ExteriorAnimation.module.css";

interface IProps {
  imageUrl: string;
  hideAnimation: () => void;
  area: "EXTERIOR" | "LIVING" | "FARM";
}

const ExteriorAnimation: React.FC<IProps> = ({
  imageUrl,
  area,
  hideAnimation,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((e) => {
        hideAnimation();
      });
    }
  }, [hideAnimation]);

  const loadImage = useCallback(() => {
    const img = document.createElement("img");
    img.src = imageUrl;
    img.onload = () => {
      playVideo();
    };
  }, [imageUrl, playVideo]);

  const animationVideo =
    area === "EXTERIOR"
      ? animationVideoExterior
      : area === "LIVING"
      ? animationVideoLiving
      : animationVideoHighway;

  const poster =
    area === "EXTERIOR"
      ? exteriorVideoPoster
      : area === "LIVING"
      ? livingVideoPoster
      : farmVideoPoster;

  return (
    <div className={styles.container}>
      <video
        ref={videoRef}
        onEnded={hideAnimation}
        onCanPlayThrough={loadImage}
        poster={poster}
      >
        <source src={animationVideo} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </div>
  );
};

export default ExteriorAnimation;
