import React, { useCallback, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletContextDefinition } from "../../types";
import styles from "./IFrame.module.css";
// import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { useAreaPointsMutation } from "../../queries/usePoints";

const platform = Capacitor.getPlatform();

interface IProps {
  areaId: string;
  productId: string;
  playEntryAudio?: () => void;
  showTopBar?: () => void;
  hideTopBar?: () => void;
  onIframeClose?: () => void;
}

const IFrame: React.FC<IProps> = ({
  productId,
  areaId,
  playEntryAudio,
  showTopBar,
  hideTopBar,
  onIframeClose,
}) => {
  // const [isBrowserStarted, setIsBrowserStarted] = useState(false);
  const { sessionId, audioManager, progress, refetchProgress } =
    useOutletContext<OutletContextDefinition>();
  const pointsMutation = useAreaPointsMutation();
  const { isAudioElementMuted } = audioManager;

  const onHelloMessageReceived = useCallback(
    (e: MessageEvent) => {
      if (e.data.type === "INITIALIZED") {
        if (playEntryAudio) {
          playEntryAudio();
        }
      }

      if (e.data.type === "FEATURE_MODE_STARTED") {
        if (hideTopBar) {
          hideTopBar();
        }
      }

      if (e.data.type === "FEATURE_MODE_ENDED") {
        if (showTopBar) {
          showTopBar();
        }
      }

      if (e.data.type === "POST_MESSAGE_IFRAME") {
        if (e.data?.payload && e.data.payload.type === "ANNOTATION") {
          const annotationId = e.data.payload.annotationId;
          pointsMutation.mutate(
            {
              progress: progress,
              areaId: areaId,
              event: `feature_annotation_${annotationId}_clicked`,
              points: 1,
            },
            {
              onSuccess: () => {
                refetchProgress();
              },
            }
          );
        }
      }
    },
    [
      showTopBar,
      hideTopBar,
      playEntryAudio,
      areaId,
      progress,
      pointsMutation,
      refetchProgress,
    ]
  );

  useEffect(() => {
    if (window) {
      window.addEventListener("message", onHelloMessageReceived);
    }
    return () => {
      if (window) {
        window.removeEventListener("message", onHelloMessageReceived);
      }
    };
  }, [onHelloMessageReceived]);

  // const openInAppBrowser = async () => {
  //   setIsBrowserStarted(true);
  //   Browser.addListener("browserFinished", () => {
  //     Browser.removeAllListeners();
  //     if (onIframeClose) {
  //       onIframeClose();
  //     }
  //   });
  //   await Browser.open({
  //     url: `https://viewer.helloar.io?id=${productId}&parent_session_id=${sessionId}&mute_audio=${isAudioElementMuted.toString()}`,
  //   });
  //   if (playEntryAudio) {
  //     playEntryAudio();
  //   }
  // };

  // if (platform !== "web") {
  //   if (!isBrowserStarted) {
  //     openInAppBrowser();
  //     return (
  //       <div className={styles.loadingContainer}>
  //         <p>Please Wait...</p>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className={styles.loadingContainer}>
  //         <p>Please Wait...</p>
  //       </div>
  //     );
  //   }
  // }

  return (
    <div className={styles.container}>
      <iframe
        title={`HelloAR Viewer ${productId}`}
        src={`https://viewer.helloar.io?id=${productId}&parent_session_id=${sessionId}&mute_audio=${isAudioElementMuted.toString()}${
          platform !== "web" ? "&ar_button_position=none" : ""
        }`}
        height="100%"
        width="100%"
        allow="xr-spatial-tracking;fullscreen;"
        frameBorder="0"
      />
    </div>
  );
};

export default IFrame;
