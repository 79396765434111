import { useMutation } from "react-query";
import { SessionEventDefinition, ViewerDefinition } from "../api";
import { postSessionEvent } from "../api/events";
import { User } from "../types";

interface TriggerEventDefinition {
  event: string;
  account_id?: string;
  account_session_id?: string;
  parameters?: {
    [key: string]: any;
  };
}

export const useTriggerEvent = (
  viewerData: ViewerDefinition,
  user: User | undefined,
  sessionId: string
) => {
  const triggerEvent = useMutation<boolean, any, TriggerEventDefinition>(
    (data) => {
      const dataToPost: SessionEventDefinition = {
        application_id: viewerData.application,
        ...(user?.account_id && { account_id: user.account_id }),
        ...(user?.session_id && { account_session_id: user.session_id }),
        session_id: sessionId,
        viewer_id: viewerData._id,
        ...data,
      };
      return postSessionEvent(dataToPost);
    }
  );
  return {
    triggerEvent,
  };
};
