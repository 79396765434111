import { AxiosRequestConfig } from "axios";
import { axiosClient } from "./client";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const DOC_GENERATOR_URL =
  "https://docgenerator-n3drlt7x5a-el.a.run.app/generate-invoice";
const CERTIFICATE_TEMPLATE_ID = "1FoAdyU7nYKmEMnFa3MKRVC1EDsR73RIZ4ttCzXmAZUM";

export const generateCertificate = async (name: string, courseName: string) => {
  try {
    const config: AxiosRequestConfig = {
      url: DOC_GENERATOR_URL,
      method: "POST",
      data: {
        template_file_id: CERTIFICATE_TEMPLATE_ID,
        type: "docs",
        optional_parameters: {
          DATE: new Date().toLocaleDateString("en-IN"),
          COURSE_NAME: courseName,
          NAME: name,
        },
      },
      responseType: "arraybuffer",
    };

    const courseNameStripped = courseName.replace(/[^a-z0-9.]/gi, "-");
    const uploadPath = `lms_certificates/${courseNameStripped}/${uuidv4()}/certificate.pdf`;
    const response = await axiosClient.request<ArrayBuffer>(config);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const file = new File([blob], "certificate");
    const url = uploadContent(uploadPath, "application/pdf", file);
    return url;
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't patch certificate :( Please try again later.";
  }
};

interface GetSignedUrlResponse {
  data: {
    url: string;
    fields: {
      acl: string;
      "Content-Type": string;
      key: string;
      "x-amz-algorithm": string;
      "x-amz-credential": string;
      "x-amz-date": string;
      policy: string;
      "x-amz-signature": string;
    };
  };
  uploaded_url: string;
}

export const uploadContent = async (
  fileName: string,
  fileType: string,
  file: any,
  applicationId: string | null = null
): Promise<string> => {
  const configGetS3Url: AxiosRequestConfig = {
    url: `/signed_file`,
    params: {
      file_name: fileName,
      file_type: fileType,
      ...(applicationId && { application_id: applicationId }),
    },
    method: "GET",
  };
  try {
    const response = await axiosClient.request<GetSignedUrlResponse>(
      configGetS3Url
    );
    let formData = new FormData();
    let key:
      | "acl"
      | "Content-Type"
      | "key"
      | "x-amz-algorithm"
      | "x-amz-credential"
      | "x-amz-date"
      | "policy"
      | "x-amz-signature";
    for (key in response.data.data.fields) {
      formData.append(key, response.data.data.fields[key]);
    }
    formData.append("file", file);
    const configUploadToS3: AxiosRequestConfig = {
      url: response.data.data.url,
      data: formData,
      method: "post",
    };
    await axios.request(configUploadToS3);
    return response.data.uploaded_url;
  } catch (err) {
    throw new Error("Error while uploading content");
  }
};
