import { AxiosRequestConfig } from "axios";
import { axiosClient } from "./client";
import { ApplicationRoleDefinition, ApplicationRolesDefinition } from "./types";

export const getApplicationRoles = async (
  account: string,
  application: string,
  viewer?: string
): Promise<ApplicationRoleDefinition> => {
  try {
    if (!(account || application)) {
      throw new Error("No account or application id passed");
    }
    const whereQuery = {
      ...(account && { account }),
      ...(application && { application }),
      ...(viewer && { viewer }),
    };
    const config: AxiosRequestConfig = {
      url: "application_roles",
      method: "get",
      params: {
        where: whereQuery,
      },
    };
    const response = await axiosClient.request<ApplicationRolesDefinition>(
      config
    );
    if (response?.data && response.data._items.length > 0) {
      return response.data._items[0];
    }
    throw new Error("No roles found for this id");
  } catch (err: any) {
    //TODO: log error to server
    throw err;
  }
};
