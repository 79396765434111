import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import styles from "./Header.module.css";
import playButton from "../../assets/images/play.png";
import muteButton from "../../assets/images/mute.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import NavigationModal from "../NavigationModal";
import dropDownIcon from "../../assets/images/drop-down.svg";
import { showNavigationModal } from "../../store/slices/navigationSlice";
import placeholderLogo from "../../assets/images/brand_logo.svg";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import profileIcon from "../../assets/icons/profile.png";
import LoadingModal from "../LoadingModal";
import { OutletContextDefinition } from "../../types";
import { AreaDefinition } from "../../api";
import { getPointsFromMap } from "../../utils/lmsDataHelpers";

interface IProps {
  areaData: AreaDefinition;
}

const Header: React.FC<IProps> = ({ areaData }) => {
  const { viewerData, audioManager } =
    useOutletContext<OutletContextDefinition>();
  const logo =
    viewerData?.brand_details?.logo && viewerData.brand_details.logo.length > 0
      ? viewerData.brand_details.logo
      : placeholderLogo;
  const { shouldShowNavigation } = useAppSelector((state) => state.navigation);
  const { areasProgress } = useAppSelector((state) => state.progress);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: audioState,
    showAudioBlocker,
    executeFunctionNow,
  } = audioManager;

  const onAudioButtonClick = () => {
    audioManager.onAudioButtonClicked();
  };

  const onAreaClicked = () => {
    const tmpFn = () => {
      dispatch(showNavigationModal());
    };
    executeFunctionNow(tmpFn);
  };

  if (!areaData) {
    return <></>;
  }

  const currentAreaProgress = areasProgress.find(
    (ap) => ap.area === areaData._id
  );

  const areaPoints = getPointsFromMap(currentAreaProgress?.area_points_map);

  return (
    <>
      <Row className={styles.container}>
        <Col
          xs={9}
          md={5}
          onClick={onAreaClicked}
          className={styles.leftColContainer}
        >
          {!location.pathname.includes("profile") && (
            <>
              {areaData.name !== "Exterior" && (
                <>
                  <div className={styles.headingContainer}>
                    <h4 className={styles.areaName}>{areaData.name}</h4>
                  </div>
                  <Image src={dropDownIcon} />
                  {currentAreaProgress &&
                    currentAreaProgress.is_area_completed &&
                    areaPoints !== 0 && (
                      <p className={styles.points}>
                        Points Earned: {areaPoints}
                      </p>
                    )}
                </>
              )}
            </>
          )}

          {location.pathname.includes("profile") && (
            <>
              <h4 className={styles.areaName}>Profile</h4>
              <Image src={dropDownIcon} />
            </>
          )}
        </Col>
        <Col
          xs={0}
          md={2}
          className="d-none d-md-flex h-100"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image
            src={logo}
            style={{
              maxWidth: 200,
              maxHeight: "100%",
              padding: "10px 15px",
              objectFit: "contain",
            }}
          />
        </Col>
        <Col
          xs={3}
          md={5}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Image
            onClick={onAudioButtonClick}
            className={styles.audioButton}
            src={audioState === "STARTED" ? playButton : muteButton}
          />

          <Image
            onClick={() => {
              if (!location.pathname.includes("profile")) {
                audioManager.stopAudio();
                navigate("profile");
              }
            }}
            className={styles.profileButton}
            src={profileIcon}
          />
        </Col>
      </Row>
      <NavigationModal
        show={shouldShowNavigation}
        logo={logo}
        viewerId={viewerData._id}
      />
      <LoadingModal show={showAudioBlocker} />
    </>
  );
};

export default Header;
