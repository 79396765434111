import { AxiosRequestConfig } from "axios";
import { axiosClient } from "./client";
import {
  AreasResponseDefinition,
  UserProgressPatchDefinition,
  UserProgressResponseDefinition,
} from "./types";

export const getAreas = async (viewerId: string) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/viewer_add_ons/lms_areas`,
      method: "get",
      params: {
        where: {
          viewer: viewerId,
        },
        embedded: {
          hotspots: 1,
        },
        sort: "sort_number",
      },
    };
    const response = await axiosClient.request<AreasResponseDefinition>(config);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't fetch areas :( Please try again later.";
  }
};

export const getUserLearningProgress = async (
  accountId: string,
  viewerId: string
) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/viewer_add_ons/lms_user_progress`,
      method: "get",
      params: {
        where: {
          viewer: viewerId,
          account: accountId,
        },
      },
    };
    const response = await axiosClient.request<UserProgressResponseDefinition>(
      config
    );
    if (
      response.data &&
      response.data._items &&
      response.data._items.length > 0
    ) {
      return response.data._items[0];
    }
    return undefined;
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't fetch learning progress :( Please try again later.";
  }
};

export const postUserLearningProgress = async (
  accountId: string,
  viewerId: string
) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/viewer_add_ons/lms_user_progress`,
      method: "post",
      data: {
        viewer: viewerId,
        account: accountId,
        area_progress: [],
      },
    };
    interface PostRespone {
      _id: string;
      _status: string;
    }
    const response = await axiosClient.request<PostRespone>(config);
    if (response.data._id) {
      return response.data._id;
    }
    throw new Error("Coudln't create progress document");
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Coudln't create progress document :( Please try again later.";
  }
};

export const patchUserLearningProgress = async (
  progressId: string,
  patchData: UserProgressPatchDefinition
) => {
  try {
    //remove time fields from patchData
    const newPatchData = [];
    for (let i = 0; i < patchData.area_progress.length; i++) {
      const areaProgress = patchData.area_progress[i];
      //remove time field from learning_progress array
      const newLearningArr = [];
      for (let j = 0; j < areaProgress.learning_progress.length; j++) {
        const lp = { ...areaProgress.learning_progress[j] };
        delete lp.started_at;
        delete lp.completed_at;
        newLearningArr.push(lp);
      }
      areaProgress.learning_progress = newLearningArr;

      const newAssessmentArr = [];
      for (let j = 0; j < areaProgress.assessment_progress.length; j++) {
        const ap = { ...areaProgress.assessment_progress[j] };
        delete ap.started_at;
        delete ap.completed_at;
        newAssessmentArr.push(ap);
      }
      areaProgress.assessment_progress = newAssessmentArr;
      newPatchData.push(areaProgress);
    }

    const config: AxiosRequestConfig = {
      url: `/viewer_add_ons/lms_user_progress/${progressId}`,
      method: "patch",
      data: {
        area_progress: newPatchData,
      },
    };
    interface PostRespone {
      _id: string;
      _status: string;
    }
    const response = await axiosClient.request<PostRespone>(config);
    if (response.data._id) {
      return response.data._id;
    }
    throw new Error("Couldn't patch progress");
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't patch progress :( Please try again later.";
  }
};

export const patchCompletionCertificate = async (
  progressId: string,
  certificate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/viewer_add_ons/lms_user_progress/${progressId}`,
      method: "patch",
      data: {
        documents: {
          certificate_pdf: certificate,
        },
      },
    };
    interface PostRespone {
      _id: string;
      _status: string;
    }
    const response = await axiosClient.request<PostRespone>(config);
    if (response.data._id) {
      return response.data._id;
    }
    throw new Error("Couldn't patch certificate");
  } catch (err: any) {
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't patch certificate :( Please try again later.";
  }
};
