import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { QuestionDefinition } from "../../api";
import IFrame from "../IFrame";
import LearningCompleted from "../LearningCompleted";
import LearningModule from "../LearningModule";
import Video from "../Video";
import styles from "./ThreeSixtyModal.module.css";

interface IProps {
  show: boolean;
  areaId: string;
  modalType:
    | "PRODUCT"
    | "LEARNING_COMPLETE"
    | "MODULE"
    | "VIDEO"
    | "MODULE_COMPLETE"
    | "PRESENTATION";
  productId: string;
  videoUrl: string;
  questions?: QuestionDefinition[];
  hotspotName: string;
  setShow: (shouldShow: boolean) => void;
  playExitAudio: () => void;
  playEntryAudio: () => void;
  learnProductAgain: () => void;
  takeAssessmentAgain: () => void;
  onAssessmentComplete: (result: "PASSED" | "FAILED") => void;
  handleLearningComplete: () => void;
}

const ThreeSixtyModal: React.FC<IProps> = ({
  show,
  areaId,
  modalType,
  videoUrl,
  productId,
  questions,
  hotspotName,
  setShow,
  playExitAudio,
  playEntryAudio,
  learnProductAgain,
  takeAssessmentAgain,
  onAssessmentComplete,
  handleLearningComplete,
}) => {
  const [showExitScreen, setShowExitScreen] = useState(false);
  const [shouldShowTopBar, setShouldShowTopBar] = useState(true);

  const isLearningComplete =
    modalType === "LEARNING_COMPLETE" || modalType === "MODULE_COMPLETE";

  const isModalSmall = showExitScreen || isLearningComplete;

  const onClose = () => {
    if (isLearningComplete) {
      setShow(false);
    } else {
      setShowExitScreen(true);
    }
  };
  const hideTopBar = () => {
    setShouldShowTopBar(false);
  };
  const showTopBar = () => {
    setShouldShowTopBar(true);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName={styles.modalDialog}
      contentClassName={`${styles.modalContent} ${
        isModalSmall ? styles.modalContentSmall : ""
      }`}
      backdropClassName={styles.modalBackdrop}
    >
      <Modal.Body>
        <Row>
          {shouldShowTopBar && (
            <Col xs={12} className={styles.topContainer}>
              {!showExitScreen && (
                <div onClick={onClose}>
                  <p>x</p>
                  <p>{showExitScreen}</p>
                </div>
              )}
            </Col>
          )}
        </Row>
        <Row
          className={`${styles.contentContainer} ${
            !shouldShowTopBar || showExitScreen
              ? styles.contentContainerFullHeight
              : ""
          }`}
        >
          {!showExitScreen && (
            <Col xs={12}>
              {modalType === "VIDEO" && (
                <Video videoUrl={videoUrl} onVideoEnded={onClose} />
              )}
              {modalType === "PRODUCT" && (
                <IFrame
                  areaId={areaId}
                  productId={productId}
                  showTopBar={showTopBar}
                  hideTopBar={hideTopBar}
                  playEntryAudio={playEntryAudio}
                  onIframeClose={onClose}
                />
              )}
              {modalType === "MODULE" && questions && (
                <LearningModule
                  questions={questions}
                  onAssessmentComplete={onAssessmentComplete}
                  hotspotName={hotspotName}
                />
              )}
              {isLearningComplete && (
                <LearningCompleted
                  learnProduct={learnProductAgain}
                  takeAssessment={takeAssessmentAgain}
                  showAssessmentButton={!!(questions && questions.length > 0)}
                  isAssessmentDone={modalType === "MODULE_COMPLETE"}
                />
              )}
            </Col>
          )}
          {showExitScreen && modalType !== "MODULE" && (
            <Col xs={12} className={styles.exitContainer}>
              <Row style={{ width: "100%" }}>
                <Col xs={12}>
                  <h3>Are you done learning?</h3>
                </Col>
              </Row>
              <Row className={styles.exitButtonsContainer}>
                <Col
                  xs={12}
                  md={{ span: 4, offset: 2 }}
                  className={styles.exitButtonContainer}
                >
                  <Button
                    onClick={() => {
                      setShowExitScreen(false);
                    }}
                    variant="outline-dark"
                    size="lg"
                  >
                    No
                  </Button>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 4 }}
                  className={styles.exitButtonContainer}
                >
                  <Button
                    onClick={() => {
                      setShowExitScreen(false);
                      playExitAudio();
                      handleLearningComplete();
                    }}
                    variant="dark"
                    size="lg"
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          {showExitScreen && modalType === "MODULE" && (
            <Col xs={12} className={styles.exitContainer}>
              <Row style={{ width: "100%" }}>
                <Col xs={12}>
                  <h3>Assessment is incomplete. Do you want to exit?</h3>
                </Col>
              </Row>
              <Row className={styles.exitButtonsContainer}>
                <Col xs={12} md={6} className={styles.exitButtonContainer}>
                  <Button
                    onClick={() => {
                      setShowExitScreen(false);
                    }}
                    variant="outline-dark"
                    size="lg"
                  >
                    No, I'll continue.
                  </Button>
                </Col>
                <Col xs={12} md={6} className={styles.exitButtonContainer}>
                  <Button
                    onClick={() => {
                      setShow(false);
                    }}
                    variant="dark"
                    size="lg"
                  >
                    Yes, cancel assesment.
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ThreeSixtyModal;
