import React from "react";
import { Button, Col, Row } from "react-bootstrap";
//import styles from "./LearningCompleted.module.css";

interface IProps {
  learnProduct: () => void;
  takeAssessment: () => void;
  showAssessmentButton: boolean;
  isAssessmentDone: boolean;
}

const singleButtonSpan = {
  span: 6,
  offset: 3,
};

const LearningCompleted: React.FC<IProps> = ({
  learnProduct,
  takeAssessment,
  showAssessmentButton,
  isAssessmentDone,
}) => {
  const title = isAssessmentDone
    ? "You have already completed this course!"
    : "Learning complete. Take assessment now.";
  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{ height: "100%" }}
    >
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-center mb-2 mb-lg-5 text-center"
        >
          <h4>{title}</h4>
        </Col>
        <Col
          xs={12}
          md={showAssessmentButton ? 6 : singleButtonSpan}
          lg={showAssessmentButton ? { span: 3, offset: 3 } : singleButtonSpan}
          className="mb-4 mb-md-0"
        >
          <Button
            variant="outline-dark"
            style={{ width: "100%" }}
            onClick={learnProduct}
          >
            Learn Again
          </Button>
        </Col>
        {showAssessmentButton && (
          <Col xs={12} md={6} lg={{ span: 3 }}>
            <Button
              variant="outline-dark"
              style={{ width: "100%" }}
              onClick={takeAssessment}
            >
              Assessment
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LearningCompleted;
