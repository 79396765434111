import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import FullScreenError from "./components/FullScreenError";
import LandingScreenAnim from "./components/LandingScreenAnim";
import { useUser } from "./hooks";
import { useViewer } from "./queries/useViewer";
import { v4 as uuidv4 } from "uuid";
import useAudio from "./hooks/useAudio";
import { initializeApp } from "firebase/app";
import { Helmet } from "react-helmet";

const firebaseConfig = {
  apiKey: "AIzaSyAdTIxyUJJuzhmfCxbFnLdoeVgtC1ngdj4",
  authDomain: "ashirvad-lms.firebaseapp.com",
  projectId: "ashirvad-lms",
  storageBucket: "ashirvad-lms.appspot.com",
  messagingSenderId: "938828517816",
  appId: "1:938828517816:web:49e7f311111da1c828ff4d",
};

initializeApp(firebaseConfig);

const ParentComponent = () => {
  const { viewerId } = useParams();
 
  
  const { isError, isLoading, data, error } = useViewer(viewerId ?? "");
  const { user } = useUser(data);
  const [sessionId, setSessionId] = useState("");
  const { audioManager, setAudioElement } = useAudio();

  useEffect(() => {
    const uuid = uuidv4();
    setSessionId(uuid);
  }, []);

  if (isError) {
    return (
      <FullScreenError
        msg={
          error && typeof error === "object"
            ? error.toString()
            : "There was an error"
        }
      />
    );
  }

  if (isLoading) {
    return <LandingScreenAnim />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.name ?? ""}</title>
        {/* <link
          rel="icon"
          type="image/png"
          href={data?.brand_details?.logo ?? ""}
          sizes="16x16"
        /> */}
      </Helmet>
      <Container
        fluid
        style={{
          display: "flex",
          height: "100%",
          position: "fixed",
          flexDirection: "column",
          padding: 0,
          margin: 0,
        }}
      >
        <audio
          ref={(node) => {
            if (node) {
              setAudioElement(node);
            }
          }}
        />
        {data && (
          <Outlet
            context={{
              viewerData: data,
              user: user,
              sessionId: sessionId,
              audioManager: audioManager,
            }}
          />
        )}
      </Container>
    </>
  );
};

export default ParentComponent;
