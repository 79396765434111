import { useCallback, useRef, useState } from "react";
import { AudioManagerType, AudioStates } from "../types";

const useAudio = () => {
  const [audioElement, setAudioElement] = useState<HTMLAudioElement>();
  const [audioState, setAudioState] = useState<AudioStates>("NOT_STARTED");
  const [showAudioBlocker, setShowAudioBlocker] = useState(false);
  const functionToExecuteLater = useRef<any>();

  const stopAudio = useCallback(() => {
    if (!audioElement) {
      return;
    }
    audioElement.currentTime = 0;
    audioElement.pause();
    audioElement.src = "";
    setAudioState("NO_AUDIO");
  }, [audioElement]);

  const pauseAudio = useCallback(() => {
    if (!audioElement) {
      return;
    }
    audioElement.pause();
    setAudioState("PAUSED");
  }, [audioElement]);

  const replayAudio = useCallback(() => {
    if (!audioElement) {
      return;
    }
    audioElement.currentTime = 0;
    audioElement.play();
    setAudioState("STARTED");
  }, [audioElement]);

  const startAudio = useCallback(
    (audioSrc: string) => {
      if (!audioElement) {
        return;
      }

      if (audioSrc === "") {
        setAudioState("NO_AUDIO");
        return;
      }

      const onAudioComplete = () => {
        if (functionToExecuteLater.current) {
          //audio state
          functionToExecuteLater.current();
          functionToExecuteLater.current = null;
        }
        setAudioState("COMPLETED");
      };

      audioElement.oncanplay = () => null;
      audioElement.src = audioSrc;
      audioElement.load();

      audioElement.onplaying = () => {
        setAudioState("STARTED");
      };

      audioElement.onended = onAudioComplete;
      audioElement.onpause = pauseAudio;

      if (audioElement.muted) {
        setAudioState("NOT_STARTED");
        return;
      }

      audioElement.oncanplay = () => {
        audioElement.play().catch((err) => {
          setAudioState("NOT_STARTED");
        });
      };
    },
    [audioElement, pauseAudio]
  );

  const setAudioSrc = useCallback(
    (audioSrc: string) => {
      if (!audioElement) {
        return;
      }

      if (audioSrc === "") {
        setAudioState("NO_AUDIO");
        return;
      }

      const onAudioComplete = () => {
        if (functionToExecuteLater.current) {
          //audio state
          functionToExecuteLater.current();
          functionToExecuteLater.current = null;
        }
        setAudioState("COMPLETED");
      };

      audioElement.oncanplay = () => null;
      audioElement.src = audioSrc;
      audioElement.load();

      audioElement.onplaying = () => {
        setAudioState("STARTED");
      };

      audioElement.onended = onAudioComplete;
      audioElement.onpause = pauseAudio;

      if (audioElement.muted) {
        setAudioState("NOT_STARTED");
        return;
      }
    },
    [audioElement, pauseAudio]
  );

  const startAudioArray = useCallback(
    (
      audiosArray: { url: string }[],
      onProgress?: (currentIndex: number, totalAudios: number) => void,
      onComplete?: () => void
    ) => {
      let currentIndex = 0;
      if (!audioElement) {
        return;
      }
      audioElement.oncanplay = () => null;
      audioElement.src = audiosArray[currentIndex].url;
      audioElement.load();

      if (audiosArray.length === 0) {
        setAudioState("NO_AUDIO");
        return;
      }

      const onAudioComplete = () => {
        if (audiosArray.length > 1 && currentIndex + 1 < audiosArray.length) {
          currentIndex++;
          audioElement.src = audiosArray[currentIndex].url;
          if (onProgress) {
            onProgress(currentIndex, audiosArray.length - 1);
          }
          playAudio();
        } else {
          setAudioState("COMPLETED");
          if (onComplete) {
            onComplete();
          }
        }
      };

      const playAudio = () => {
        audioElement.oncanplay = () => {
          audioElement.play().catch((err) => {
            setAudioState("NOT_STARTED");
          });
        };
      };

      audioElement.onplaying = () => {
        setAudioState("STARTED");
        if (onProgress) {
          onProgress(currentIndex, audiosArray.length - 1);
        }
      };

      audioElement.onended = onAudioComplete;
      audioElement.onpause = pauseAudio;

      if (audioElement.muted) {
        setAudioState("NOT_STARTED");
        return;
      }
      playAudio();
    },
    [audioElement, pauseAudio]
  );

  const startTemporaryAudio = useCallback(
    (audioSrc: string) => {
      if (!audioElement || audioElement.muted) {
        return;
      }
      const prevSrc = audioElement.src;
      const prevOnEnded = audioElement.onended;
      const onAudioComplete = () => {
        audioElement.oncanplay = () => null;
        audioElement.onended = prevOnEnded;
        audioElement.src = prevSrc;
        audioElement.load();
        setAudioState("NOT_STARTED");
      };
      audioElement.src = audioSrc;
      audioElement.load();
      audioElement.onended = onAudioComplete;
      audioElement.oncanplay = () => {
        audioElement.play().catch((err) => {
          onAudioComplete();
        });
      };
    },
    [audioElement]
  );

  const executeFunctionNow = useCallback(
    (fn: any) => {
      if (audioState !== "STARTED") {
        fn();
        setShowAudioBlocker(false);
      } else {
        setShowAudioBlocker(true);
        setTimeout(() => {
          setShowAudioBlocker(false);
        }, 1000);
        return;
      }
    },
    [audioState]
  );

  const executeFunctionLater = useCallback((fn: any) => {
    functionToExecuteLater.current = fn;
  }, []);

  const onAudioButtonClicked = useCallback(() => {
    if (!audioElement) {
      return;
    }
    if (audioState === "NO_AUDIO") {
      alert("No audio");
      return;
    }
    if (audioState === "STARTED") {
      audioElement.pause();
      setAudioState("PAUSED");
      audioElement.muted = true;
    }
    if (audioState === "COMPLETED" || audioState === "NOT_STARTED") {
      audioElement.muted = false;
      audioElement.currentTime = 0;
      audioElement.play();
      setAudioState("STARTED");
    }
    if (audioState === "PAUSED") {
      audioElement.muted = false;
      audioElement.play();
      setAudioState("STARTED");
    }
  }, [audioElement, audioState]);

  const audioManager: AudioManagerType = {
    state: audioState,
    showAudioBlocker: showAudioBlocker,
    isAudioElementMuted: audioElement ? audioElement.muted : false,
    startAudio: startAudio,
    startAudioArray: startAudioArray,
    setAudioSrc: setAudioSrc,
    startTemporaryAudio: startTemporaryAudio,
    pauseAudio: pauseAudio,
    stopAudio: stopAudio,
    replayAudio: replayAudio,
    onAudioButtonClicked: onAudioButtonClicked,
    executeFunctionNow: executeFunctionNow,
    executeFunctionLater: executeFunctionLater,
  };

  return {
    setAudioElement: setAudioElement,
    audioManager: audioManager,
  };
};

export default useAudio;
