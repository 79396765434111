import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import playIcon from "../../assets/images/play.png";
import muteIcon from "../../assets/images/mute.png";
import styles from "./AudioPlayer.module.css";
const AudioPlayer = ({ audio }: { audio: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const onAudioButtonClicked = () => {
    if (audioRef.current) {
      if (isPlaying === true) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <audio ref={audioRef}>
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Button
        onClick={onAudioButtonClicked}
        className="position-absolute bg-white rounded-circle shadow-lg border-0 p-0"
      >
        <img src={isPlaying ? playIcon : muteIcon} alt="audio" />
      </Button>
    </div>
  );
};

export default AudioPlayer;
