import { Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import ThreeSixty from "../../components/ThreeSixty";
import { useAppSelector } from "../../store/hooks";
import styles from "./Home.module.css";

const Home: React.FC = () => {
  const { areaData } = useAppSelector((state) => state.threeSixtyViewer);

  if (!areaData) {
    return <p>Please wait...</p>;
  }
  return (
    <>
      <div className={styles.header}>
        <Header areaData={areaData} />
      </div>
      <Row
        className={styles.mainAreaContainer}
        style={{ padding: 0, margin: 0 }}
      >
        <Col style={{ height: "100%", padding: 0 }}>
          <ThreeSixty areaData={areaData} />
        </Col>
      </Row>
    </>
  );
};

export default Home;
