import PNFSrc from "./assets/images/page_not_found.svg";
import { Capacitor } from "@capacitor/core";
import { Navigate } from "react-router-dom";

const platform = Capacitor.getPlatform();

function BaseComponent() {
  if (platform !== "web") {
    return <Navigate to={"6268c5d0dce6617b4f2cb756"} replace />;
  }
  return (
    <>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <img src={PNFSrc} alt="" style={{ height: "100vh", width: "100%" }} />
      </div>
    </>
  );
}

export default BaseComponent;
