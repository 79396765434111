import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
const apiClient = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json",
  },
});

export const addAuthHeaderToAxios = (authHeader: string) => {
  apiClient.defaults.headers.common["Authorization"] = authHeader;
};

export { apiClient as axiosClient };
