import React from "react";
import { Modal, Row, Col, Image } from "react-bootstrap";
import styles from "./LoadingModal.module.css";
import soundAnimation from "../../assets/images/sound.gif";

interface IProps {
  show: boolean;
}

const LoadingModal: React.FC<IProps> = ({ show }) => {
  return (
    <Modal
      show={show}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
    >
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col xs={12} className="d-flex align-items-center justify-content-center">
            <Image src={soundAnimation} className={styles.audioGif} fluid />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
