import React, { useCallback, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Header from "../../components/Header";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { OutletContextDefinition } from "../../types";
import styles from "./Profile.module.css";
import completeIcon from "../../assets/images/status_icons/passed.png";
import incompleteIcon from "../../assets/icons/minus.png";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AssessmentStatus, LearningStatus, logout } from "../../api";
import {
  findAreaById,
  getCombinedHotspotStatus,
} from "../../utils/lmsDataHelpers";
import { useTriggerEvent } from "../../queries/useEvents";
import { removeUserFromLocalStorage } from "../../utils/localstorage";
import { useMutateCertificate } from "../../queries/useProgress";

type Status = LearningStatus | AssessmentStatus;
interface HotspotSummary {
  hotspotName: string;
  hotspotStatus: Status;
}

interface DataSummary {
  totalModules: number;
  completedModules: number;
  areasSummary: AreaSummary[];
}
interface AreaSummary {
  areaName: string;
  isAreaComplete: boolean;
  children?: HotspotSummary[];
}

const getHotspotCompletionStatus = (status: Status) => {
  switch (status) {
    case "COMPLETED":
      return "Assessment Pending";
    case "PASSED":
      return "Passed";
    case "NEW":
      return "Pending";
    case "FAILED":
      return "Assessment Failed";
    case "IN_PROGRESS":
      return "In Progress";
    default:
      return status;
  }
};

const Profile: React.FC = () => {
  const { areaData } = useAppSelector((state) => state.threeSixtyViewer);
  const { areasProgress } = useAppSelector((state) => state.progress);
  const {
    viewerData,
    areas,
    user,
    sessionId,
    audioManager,
    progress,
    refetchProgress,
  } = useOutletContext<OutletContextDefinition>();
  const navigate = useNavigate();
  const { triggerEvent } = useTriggerEvent(viewerData, user, sessionId);
  const { mutate: sendEvent } = triggerEvent;
  const certificateMutation = useMutateCertificate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    sendEvent({
      event: "profile_viewed",
    });
  }, [sendEvent]);

  const onContinueLearning = () => {
    navigate(`/${viewerData._id}`);
  };

  const onGenerateCertificate = async () => {
    if (certificateMutation.isLoading) {
      return;
    }
    let name = "";
    if (user.first_name) {
      name = user.first_name;
      if (user.last_name) {
        name += ` ${user.last_name}`;
      }
    }

    if (name === "") {
      alert("No name found for user");
      return;
    }

    let courseName = "";
    if (viewerData?.configurations) {
      const config = viewerData.configurations.find(
        (config) => config.key === "course_name"
      );
      if (config) {
        courseName = config.value;
      }
    }

    certificateMutation.mutate(
      { userName: name, courseName: courseName },
      {
        onSuccess: (_data) => {
          refetchProgress();
        },
      }
    );
  };

  const logoutUser = async () => {
    if (viewerData.access_details?.authentication_enabled) {
      sendEvent({
        event: "user_logged_out",
      });
      await logout();
      audioManager.stopAudio();
      removeUserFromLocalStorage(viewerData._id);
      dispatch({ type: "RESET_REDUX" });
      navigate(`/${viewerData._id}/login`);
    } else {
      navigate(`/${viewerData._id}`);
    }
  };

  const getDataSummary = useCallback(() => {
    let summaryData: DataSummary = {
      totalModules: 0,
      completedModules: 0,
      areasSummary: [],
    };
    let totalModules = 0;
    let completedModules = 0;

    for (let i = 0; i < areasProgress.length; i++) {
      const areaProgress = areasProgress[i];
      const areaData = findAreaById(areas, areaProgress.area);
      if (!areaData || areaData.is_sub_area_selector) {
        continue;
      }
      let areaSummary: AreaSummary = {
        areaName: areaData.name,
        isAreaComplete: !!areaProgress.is_area_completed,
      };
      const hotspots = areaData?.hotspots?.filter(
        (hot) => hot.on_click_event !== "NAVIGATION"
      );
      if (hotspots && hotspots.length > 0) {
        let hotspotsSummaryData: HotspotSummary[] = [];
        for (let i = 0; i < hotspots.length; i++) {
          const hotspot = hotspots[i];
          const hotspotLearningProgress = areaProgress.learning_progress.find(
            (ap) => ap.hotspot === hotspot._id
          );
          const hotspotAssessmentProgress =
            areaProgress.assessment_progress.find(
              (ap) => ap.hotspot === hotspot._id
            );
          const hotspotStatus = getCombinedHotspotStatus(
            hotspotLearningProgress?.status,
            hotspotAssessmentProgress?.status
          );
          hotspotsSummaryData.push({
            hotspotName: hotspot.name,
            hotspotStatus: hotspotStatus,
          });
          totalModules += 1;
          if (hotspotStatus === "PASSED") {
            completedModules += 1;
          }
        }
        areaSummary.children = hotspotsSummaryData;
      }
      summaryData.areasSummary.push(areaSummary);
    }
    summaryData.totalModules = totalModules;
    summaryData.completedModules = completedModules;
    return summaryData;
  }, [areas, areasProgress]);

  if (!areaData) {
    return <p>Please wait...</p>;
  }

  const dataSummary = getDataSummary();
  const courseCompleted =
    dataSummary.totalModules === dataSummary.completedModules;

  return (
    <>
      <Row>
        <Col>
          <Header areaData={areaData} />
        </Col>
      </Row>
      <Row className={styles.mainAreaContainer}>
        <Col style={{ height: "100%" }} className={styles.container}>
          <Row className={styles.summaryContainer}>
            {courseCompleted && (
              <Col xs={12}>
                <h5 className={styles.congrats}>Congratulations!</h5>
              </Col>
            )}
            <Col
              xs={12}
              className={styles.summaryColumn}
              style={{ margin: "20px 0 10px 0" }}
            >
              <h5>Status</h5>
              <h6>{courseCompleted ? "Cleared" : "Incomplete"}</h6>
            </Col>
            <Col xs={12} className={styles.summaryColumn}>
              <h5>Score</h5>
              <h6>
                {dataSummary.completedModules}/{dataSummary.totalModules}
              </h6>
            </Col>
            {courseCompleted && (
              <>
                {progress?.documents?.certificate_pdf && (
                  <div style={{ marginTop: 30, marginBottom: 30 }}>
                    <Col xs={12} className={styles.summaryColumn}>
                      <p className={styles.link}>
                        <a
                          href={progress.documents.certificate_pdf}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download Certificate
                        </a>
                      </p>
                    </Col>
                    <Col xs={12} className={styles.summaryColumn}>
                      <p
                        className={styles.link}
                        onClick={onGenerateCertificate}
                      >
                        {certificateMutation.isLoading
                          ? "Regenerating Certificate..."
                          : "Regenerate Certificate"}
                      </p>
                    </Col>
                  </div>
                )}
                {!progress?.documents?.certificate_pdf && (
                  <Col xs={12} className={styles.summaryColumn}>
                    <p
                      className={styles.link}
                      style={{ marginTop: 40, marginBottom: 40 }}
                      onClick={onGenerateCertificate}
                    >
                      {certificateMutation.isLoading
                        ? "Generating Certificate..."
                        : "Generate Certificate"}
                    </p>
                  </Col>
                )}
              </>
            )}
            {!courseCompleted && (
              <Col xs={12} className={styles.summaryColumn}>
                <p className={styles.link} onClick={onContinueLearning}>
                  Continue Learning
                </p>
              </Col>
            )}
            {dataSummary.areasSummary.map((areaSummary, index) => (
              <div className={styles.areaContainer} key={index}>
                <Col xs={12} className={styles.summaryColumn}>
                  <h6>{areaSummary.areaName}</h6>
                  <Image
                    src={
                      areaSummary.isAreaComplete ? completeIcon : incompleteIcon
                    }
                    className={styles.image}
                  />
                </Col>

                <Col xs={12} className={styles.summaryColumnTable}>
                  {areaSummary.children &&
                    areaSummary.children.map((hotspot, index) => (
                      <div className={styles.summaryColumnHotspot} key={index}>
                        <p>{hotspot.hotspotName}</p>
                        <p>
                          {getHotspotCompletionStatus(hotspot.hotspotStatus)}
                        </p>
                      </div>
                    ))}
                </Col>
              </div>
            ))}
            <Col xs={12} className={styles.summaryColumn}>
              <p
                className={styles.link}
                style={{ marginTop: 30, marginBottom: 0 }}
                onClick={() => {
                  navigate(-1)
                }}
              >
                Back
              </p>
              <p
                className={styles.link}
                style={{ marginTop: 30, marginBottom: 0 }}
                onClick={logoutUser}
              >
                Logout
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
