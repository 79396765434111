import React from "react";
import { Button, Modal } from "react-bootstrap";
import Video from "../Video";
import styles from "./CommonModal.module.css";

interface IProps {
  show: boolean;
  setShow: (shouldShow: boolean) => void;
  modalType: "VIDEO" | "TEXT";
  videoUrl?: string;
}

const CommonModal: React.FC<IProps> = ({
  show,
  setShow,
  modalType,
  videoUrl,
}) => {
  const onClose = () => {
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName={styles.modalDialog}
      contentClassName={`${styles.modalContent} `}
      backdropClassName={styles.modalBackdrop}
    >
      <Modal.Header style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="d-flex justify-content-center align-items-center bg-transparent border-0"
          onClick={onClose}
        >
          <img src="/assets/icons/closeIcon.png" width={"20px"} alt="close" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {modalType === "VIDEO" && videoUrl && (
          <Video videoUrl={videoUrl} onVideoEnded={onClose} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
