import { SphericalViewer } from "hello-react-sdk";
import { SphericalViewerNew } from "hello-react-sdk-v2";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setThreeSixtyData } from "../../store/slices/threeSixtyViewerSlice";
import statusIconNew from "../../assets/images/status_icons/green_dot.png";
import statusIconComplete from "../../assets/images/status_icons/blue_dot.png";
//import statusIconInProgress from "../../assets/images/status_icons/in_progress.png";
import statusIconFailed from "../../assets/images/status_icons/red_dot.png";
import statusIconPassed from "../../assets/images/status_icons/blue_dot.png";
import presentationDataJson from "../../consts/presentation.json";
import areaVideos from "../../consts/areaVideos.json";
import CommonModal from "../CommonModal/CommonModal";
import {
  showNavigationModal,
  hideAreas,
} from "../../store/slices/navigationSlice";
import { useNavigate, useOutletContext } from "react-router-dom";
import AreaPopup from "../AreaPopup";
import ThreeSixtyModal from "../ThreeSixtyModal";
import SphericalLoading from "../SphericalLoading";
import ExteriorAnimation from "../ExteriorAnimation";
import { OutletContextDefinition } from "../../types";
import { dataReducer, initialData, ModalDisplayTypes } from "./dataReducer";
import { AreaDefinition, AssessmentStatus } from "../../api";
import {
  findAreaById,
  findAreaProgress,
  getCombinedHotspotStatus,
} from "../../utils/lmsDataHelpers";
import {
  useMutateCertificate,
  useUpdateProgress,
} from "../../queries/useProgress";
import {
  ReduxUserProgressDefinition,
  setAreaAsComplete,
} from "../../store/slices/progressSlice";
import { useTriggerEvent } from "../../queries/useEvents";
import { SphericalViewerRefType } from "hello-react-sdk/dist/types/components/SphericalViewer/SphericalViewer.types";
import { SphericalViewerNewRefType } from "hello-react-sdk-v2";
import CourseCompletedModal from "../CourseCompletedModal";
import { v4 as uuidv4 } from "uuid";
import PresentationOverlay from "../PresentationOverlay";
import LandingScreenAnim from "../LandingScreenAnim";

const areasToAutoRotate = [
  "Agri Introduction",
  "Agri Portfolio",
  "Exterior",
  "Living Area",
];
const areasToShowMenu = ["Living Area"];
const areasToShowAnimation = [
  "63a2e4199a8e78997aae7eef",
  "Exterior",
  "Living Area",
];

const exteriorStillImage =
  "https://content.helloviewer.io/uploads/62624b8a0450323bda90e88e/icons/ExteriorAreaScreenshot.png";

const livingStillImage =
  "https://content.helloviewer.io/uploads/62624b8a0450323bda90e88e/icons/LivingAreaScreenshot.png";

const farmStillImage =
  "https://content.helloviewer.io/ashirvad_lms_test/24_01_2023/areas/highway.jpg";

interface IProps {
  areaData: AreaDefinition;
}

const getHotspotStatus = (
  hotspotId: string,
  progress: ReduxUserProgressDefinition
) => {
  const learningForHotspot = progress.learning_progress.find(
    (lp) => lp.hotspot === hotspotId
  );
  const assessmentForHotspot = progress.assessment_progress.find(
    (ap) => ap.hotspot === hotspotId
  );
  const status = getCombinedHotspotStatus(
    learningForHotspot?.status,
    assessmentForHotspot?.status
  );
  return status;
};

const getHotspotName = (hotspotId: string, areaData: AreaDefinition) => {
  const hotspots = areaData.hotspots;
  if (hotspots && hotspots.length > 0) {
    const hot = hotspots.find((hp) => hp._id === hotspotId);
    if (hot && hot.name) {
      return hot.name;
    }
  }
  return "";
};

const ThreeSixty: React.FC<IProps> = ({ areaData }) => {
  const threeSixtyData = areaData;
  const { viewerData, user, sessionId, areas, audioManager, refetchProgress } =
    useOutletContext<OutletContextDefinition>();
  const [showModal, setShowModal] = useState(false);
  const [showCourseCompletedModal, setShowCourseCompletedModal] =
    useState(false);
  const [state, dispatch] = useReducer(dataReducer, initialData);
  const [currentHotspotId, setCurrentHotspotId] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [disablePresentationButtons, setDisablePresentationButtons] =
    useState(false);
  const [showPresentation, setShowPresentation] = useState(false);
  const { areasProgress } = useAppSelector((state) => state.progress);
  const progress = findAreaProgress(areasProgress, threeSixtyData._id);
  const reduxDispatch = useAppDispatch();
  const { setHotspotLearningStatus, setHotspotAssessmentStatus } =
    useUpdateProgress();
  const navigate = useNavigate();
  const { triggerEvent } = useTriggerEvent(viewerData, user, sessionId);
  const { mutate: sendEvent } = triggerEvent;
  const sphericalRef = useRef<SphericalViewerRefType>(null);
  const sphericalNewRef = useRef<SphericalViewerNewRefType>(null);
  const certificateMutation = useMutateCertificate();
  const [tempSphericalImage, setTempSphericalImage] = useState("");
  const [areaVideoUrl, setAreaVideoUrl] = useState("");
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [isPreloadingAreas, setIsPreloadingAreas] = useState(true);
  const viewerConfig = viewerData.configurations;
  const presentationZoomValue = 20;
  let viewerType = "old";
  if (viewerConfig && viewerConfig.length > 0) {
    viewerType = viewerConfig[0].value.value;
  }

  const {
    startAudio,
    executeFunctionLater,
    executeFunctionNow,
    stopAudio,
    startTemporaryAudio,
  } = audioManager;

  useEffect(() => {
    dispatch({
      type: "RESET",
      payload: {},
    });
    sendEvent({
      event: "area_viewed",
      parameters: {
        area_name: threeSixtyData.name,
      },
    });
  }, [dispatch, threeSixtyData, sendEvent]);
  // useEffect(() => {
  //   setFinal(true)
  //   progress?.assessment_progress.forEach((p) => {
  //     if ( p.status !== "PASSED" ) {
  //       console.log(p.status,'statusssss');

  //     setFinal(false)
  //    }

  //   });
  //   if(!threeSixtyData.final_questions) {
  //     setFinal(false)
  //   }

  // },[progress,threeSixtyData.final_questions,currentHotspotId])

  useEffect(() => {
    // const playVideoAfterAudio = ["63abfa20c15a01140e2e5100"];
    if (!reduxDispatch || !startAudio || !executeFunctionLater) {
      return;
    }
    const onAudioCompleted = () => {
      if (sphericalRef.current) {
        sphericalRef.current.triggerZoomIn(
          10,
          threeSixtyData.camera_initial_position
        );
      }
      if (sphericalNewRef.current) {
        sphericalNewRef.current.stopAutoRotate();
        sphericalNewRef.current.setCamera(
          threeSixtyData.camera_initial_position
        );
      }
      const tempAreaVideoData = { ...areaVideos }[threeSixtyData._id];
      if (tempAreaVideoData) {
        setAreaVideoUrl(tempAreaVideoData?.videoData.url);
        setShowCommonModal(true);
      }

      // if (tempAreaVideoData) {
      //   dispatch({
      //     type: "SET_VIDEO",
      //     payload: {
      //       dataType: "VIDEO",
      //       dataId: tempAreaVideoData.videoData.url,
      //     },
      //   });
      //   dispatch({
      //     type: "SET_DISPLAY_TYPE",
      //     payload: {
      //       displayType: "VIDEO",
      //     },
      //   });
      //   setShowModal(true);
      // }
    };
    if (
      areasToShowAnimation.includes(threeSixtyData._id) ||
      areasToShowAnimation.includes(threeSixtyData.name)
    ) {
      setShowAnimation(true);
    } else {
      setShowAnimation(false);
    }

    const audio = threeSixtyData?.audio?.entry ?? "";
    if (audio === "") {
      startAudio(audio);
      setTimeout(onAudioCompleted, 1000);
    } else {
      startAudio(audio);
      executeFunctionLater(onAudioCompleted);
    }
  }, [reduxDispatch, executeFunctionLater, startAudio, threeSixtyData]);

  const onGenerateCertificate = async () => {
    try {
      let name = "";
      if (user.first_name) {
        name = user.first_name;
        if (user.last_name) {
          name += ` ${user.last_name}`;
        }
      }

      if (name === "") {
        alert("No name found for user");
        return;
      }

      let courseName = "";
      if (viewerData?.configurations) {
        const config = viewerData.configurations.find(
          (config) => config.key === "course_name"
        );
        if (config) {
          courseName = config.value;
        }
      }

      await certificateMutation.mutateAsync({
        userName: name,
        courseName: courseName,
      });
      refetchProgress();
      return;
    } catch (err) {
      return;
    }
  };
  // const onFinalQuestionComplete = (assessmentStatus: AssessmentStatus) => {
  //     if(assessmentStatus==="PASSED"){
  //       console.log('assessment completed successfully final');
  //      setFinal(false)
  //     }
  //     setShowModal(false)

  //   };
  const onHotspotComplete = (assessmentStatus: AssessmentStatus) => {
    try {
      let alreadyPassedAssessment = false;
      progress?.assessment_progress.forEach((p) => {
        if (p.hotspot === currentHotspotId && p.status === "PASSED") {
          alreadyPassedAssessment = true;
        }
      });
      if (alreadyPassedAssessment) {
        setShowModal(false);
        return;
      }
      const totalQuestions = state.questions ? state.questions.length : 0;
      setHotspotAssessmentStatus.mutate(
        {
          areaId: areaData._id,
          hotspotId: currentHotspotId,
          status: assessmentStatus,
          areasProgress: areasProgress,
          total_questions: totalQuestions,
          num_of_questions_completed:
            assessmentStatus === "PASSED" ? totalQuestions : 0,
          ...(assessmentStatus === "PASSED" &&
            state?.questions?.length && {
            pointEvent: {
              event: `hotspot_${currentHotspotId}_assessment_complete`,
              points: 1,
            },
          }),
        },
        {
          onSuccess: () => {
            refetchProgress();
          },
        }
      );
      if (assessmentStatus === "PASSED") {
        let isAreaComplete = true;
        if (!progress) {
          isAreaComplete = false;
        } else {
          progress.assessment_progress.forEach((p) => {
            if (p.hotspot !== currentHotspotId && p.status !== "PASSED") {
              isAreaComplete = false;
            }
          });
        }
        // if(isAreaComplete){
        //   if(threeSixtyData.final_questions){
        //     isAreaComplete = false;
        //     setTimeout(() => {
        //        dispatch({
        //           type: "SET_DISPLAY_TYPE",
        //           payload: {
        //             displayType: "MODULE",
        //           },
        //         });
        //         dispatch({
        //           type: "SET_MODULE",
        //           payload: {
        //             questions: threeSixtyData.final_questions,
        //           },
        //         });
        //         setShowModal(true);
        //       setFinal(true)
        //      }, 100);
        //   }
        // }
        if (isAreaComplete) {
          onAreaCompleted();
          sendEvent({
            event: "area_learning_completed",
            parameters: {
              area_id: threeSixtyData._id,
            },
          });
        }
      }
      setShowModal(false);
      sendEvent({
        event:
          assessmentStatus === "PASSED"
            ? "hotspot_assessment_passed"
            : "hotspot_assessment_failed",
        parameters: {
          hotspot_id: currentHotspotId,
        },
      });
    } catch (err) {
      console.error("Error while updating backend");
    }
  };

  const onAreaCompleted = async () => {
    reduxDispatch(setAreaAsComplete(areaData._id));
    if (!areasProgress) {
      return;
    }

    let parentCompletion = false;
    if (threeSixtyData.parent_area) {
      parentCompletion = true;
      const parentArea = findAreaById(areas, threeSixtyData.parent_area);
      if (parentArea?.sub_areas) {
        const childAreaIds = parentArea.sub_areas.map((child) => child.area);
        for (let idx in childAreaIds) {
          const id = childAreaIds[idx];
          if (id === threeSixtyData._id) {
            continue;
          }
          if (!areasProgress.find((ap) => ap.area === id)?.is_area_completed) {
            parentCompletion = false;
            break;
          }
        }
        if (parentCompletion) {
          //set parent area as complete
          reduxDispatch(setAreaAsComplete(parentArea._id));
        } else {
          reduxDispatch(setThreeSixtyData(parentArea));
          return;
        }
      }
    }

    const idsToIgnore = [threeSixtyData._id];
    if (parentCompletion) {
      idsToIgnore.push(threeSixtyData.parent_area ?? "");
    }

    let isCourseComplete = true;
    areasProgress.forEach((ap) => {
      if (!idsToIgnore.includes(ap.area) && !ap.is_area_completed) {
        isCourseComplete = false;
      }
    });

    if (isCourseComplete) {
      sendEvent({
        event: "lms_learning_completed",
      });
      stopAudio();
      const configs = viewerData.configurations;
      if (configs && configs.length > 0) {
        const courseCompletedAudioConfig = configs.find(
          (config) => config.key === "course_completed_audio"
        );
        if (courseCompletedAudioConfig) {
          const audio = courseCompletedAudioConfig.value;
          if (audio && typeof audio === "string" && audio.length > 0) {
            startAudio(audio);
          }
        }
      }
      setShowCourseCompletedModal(true);
      let timer: NodeJS.Timeout | null = setTimeout(() => {
        navigate("profile");
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
      }, 7000);
      await onGenerateCertificate();
      if (timer) {
        clearTimeout(timer);
        navigate("profile");
      }
      return;
    }
    reduxDispatch(hideAreas());
    reduxDispatch(showNavigationModal());
    stopAudio();
    setTimeout(() => {
      if (threeSixtyData?.audio?.exit) {
        startAudio(threeSixtyData.audio.exit);
      }
    }, 50);
  };

  const playExitAudio = useCallback(() => {
    const hotspot =
      threeSixtyData &&
        threeSixtyData.hotspots &&
        threeSixtyData.hotspots.length > 0
        ? threeSixtyData.hotspots.find((hot) => hot._id === currentHotspotId)
        : undefined;
    if (hotspot?.audio?.exit && hotspot.audio.exit.length > 0) {
      startTemporaryAudio(hotspot.audio.exit);
    }
  }, [currentHotspotId, threeSixtyData, startTemporaryAudio]);

  const playEntryAudio = useCallback(() => {
    const hotspot =
      threeSixtyData &&
        threeSixtyData.hotspots &&
        threeSixtyData.hotspots.length > 0
        ? threeSixtyData.hotspots.find((hot) => hot._id === currentHotspotId)
        : undefined;

    if (hotspot?.audio?.entry && hotspot.audio.entry.length > 0) {
      startTemporaryAudio(hotspot.audio.entry);
    }
  }, [currentHotspotId, threeSixtyData, startTemporaryAudio]);

  const getStatusIcon = useCallback(
    (hotspotId: string) => {
      if (!progress) {
        return statusIconNew;
      }
      const status = getHotspotStatus(hotspotId, progress);
      switch (status) {
        case "NEW":
          return statusIconNew;
        case "IN_PROGRESS":
          return statusIconNew;
        case "COMPLETED":
          return statusIconComplete;
        case "PASSED":
          return statusIconPassed;
        case "FAILED":
          return statusIconFailed;
      }
    },
    [progress]
  );

  const hotspots = useMemo(() => {
    return threeSixtyData.hotspots && progress
      ? threeSixtyData.hotspots.map((hot, index) => {
        const status = getHotspotStatus(hot._id, progress);
        let hotspot: any = { ...hot, id: `${hot._id}-${uuidv4()}` };

        //find imageUrl
        if (hot.image_url && hot.image_url !== "") {
          hotspot.imageUrl = hot.image_url;
        } else {
          hotspot.imageUrl = getStatusIcon(hot._id);
        }

        //set onClick function
        if (hot.on_click_event === "NAVIGATION") {
          const tempFn = () => {
            if (hot.video_data?.url && hot.video_data.url !== "") {
              sphericalNewRef.current?.resetCamera({ resetFov: true });
              sphericalNewRef.current?.stopAutoRotate();
              sphericalNewRef.current?.disableCameraRotation();
              sphericalNewRef.current?.play2dVideo({
                videos: [
                  {
                    videoOptions: {
                      url: hot.video_data?.url,
                    },
                    width: hot.video_data?.scale.width,
                    height: hot.video_data?.scale.length,
                    position: hot.video_data?.position,
                  },
                ],
                onVideoStart: () => {
                  setCurrentHotspotId(hot._id);
                  const destinationArea = findAreaById(
                    areas,
                    hot.destination_area
                  );
                  if (destinationArea) {
                    reduxDispatch(setThreeSixtyData(destinationArea));
                    sendEvent({
                      event: "hotspot_clicked",
                      parameters: {
                        type: "NAVIGATION",
                        hotspot_id: hot._id,
                        source_area: threeSixtyData._id,
                        destination_area: destinationArea._id,
                      },
                    });
                  }
                  sphericalNewRef.current?.triggerZoomOut({ fovDiff: 15 });
                },
                onVideoEnd: () => {
                  sphericalNewRef.current?.enableCameraRotation();
                  sphericalNewRef.current?.unhideAllHotspots();
                },
              });
            } else {
              setCurrentHotspotId(hot._id);
              const destinationArea = findAreaById(
                areas,
                hot.destination_area
              );
              if (destinationArea) {
                reduxDispatch(setThreeSixtyData(destinationArea));
                sendEvent({
                  event: "hotspot_clicked",
                  parameters: {
                    type: "NAVIGATION",
                    hotspot_id: hot._id,
                    source_area: threeSixtyData._id,
                    destination_area: destinationArea._id,
                  },
                });
              }
            }
          };
          hotspot.onClick = () => {
            executeFunctionNow(tempFn);
          };
        } else if (hot.on_click_event === "PRESENTATION") {
          const onPresentationStarted = (hotspotId: string) => {
            setShowPresentation(true);
            // sphericalNewRef.current?.hideAllHotspots();
            sphericalNewRef.current?.triggerZoomIn({
              fovDiff: presentationZoomValue,
              cameraPosition: threeSixtyData?.camera_initial_position,
            });
            const transitionVideoData = { ...presentationDataJson }[hotspotId]
              ?.transitionVideo;

            if (transitionVideoData && sphericalNewRef.current) {
              const { parameters } = transitionVideoData;
              sphericalNewRef.current.play2dVideo({
                videos: [{ ...parameters }],
                onVideoStart: async () => {
                  sphericalNewRef?.current?.resetCamera({ resetFov: true });
                  const data = { ...presentationDataJson }[hotspotId];
                  const transitionAudioData = data?.audios;
                  const tempImage = data?.sphericalImageUrl;
                  setDisablePresentationButtons(true);
                  setTempSphericalImage(tempImage ? tempImage : "");
                  if (transitionAudioData && transitionAudioData.length > 0) {
                    for (let audio of transitionAudioData) {
                      await audioManager.startAudio(audio.url);
                    }
                  }
                },
                onVideoEnd: () => {
                  sphericalNewRef?.current?.enableCameraRotation();
                  setDisablePresentationButtons(false);
                  // sphericalNewRef.current?.hideAllHotspots();
                },
              });
            }
          };
          const tempFn = () => {
            setCurrentHotspotId(hot._id);
            if (status === "NEW") {
              setHotspotLearningStatus.mutate(
                {
                  areaId: threeSixtyData._id,
                  hotspotId: hot._id,
                  status: "IN_PROGRESS",
                  areasProgress: areasProgress,
                  pointEvent: {
                    event: `hotspot_${hot._id}_clicked`,
                    points: 2,
                  },
                },
                {
                  onSuccess: () => {
                    refetchProgress();
                  },
                }
              );
            }
            if (status === "COMPLETED") {
              dispatch({
                type: "SET_DISPLAY_TYPE",
                payload: {
                  displayType: "LEARNING_COMPLETE",
                },
              });
              setShowModal(true);
            } else if (status === "PASSED") {
              dispatch({
                type: "SET_DISPLAY_TYPE",
                payload: {
                  displayType: "MODULE_COMPLETE",
                },
              });
              setShowModal(true);
            } else {
              onPresentationStarted(hot._id);
            }

            if (hot.questions && hot.questions.length > 0) {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: hot.questions,
                },
              });
            } else {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: undefined,
                },
              });
            }

            if (status === "COMPLETED") {
              dispatch({
                type: "SET_DISPLAY_TYPE",
                payload: {
                  displayType: "LEARNING_COMPLETE",
                },
              });
              setShowModal(true);
            } else if (status === "PASSED") {
              dispatch({
                type: "SET_DISPLAY_TYPE",
                payload: {
                  displayType: "MODULE_COMPLETE",
                },
              });
              setShowModal(true);
            } else {
              onPresentationStarted(hot._id);
            }

            sendEvent({
              event: "hotspot_clicked",
              parameters: {
                type: "PRESENTATION",
                hotspot_id: hot._id,
              },
            });
            if (hot.questions && hot.questions.length > 0) {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: hot.questions,
                },
              });
            } else {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: undefined,
                },
              });
            }
          };
          hotspot.onClick = () => {
            executeFunctionNow(tempFn);
          };
        } else {
          const dataType = hot.on_click_event;
          const dataId = dataType === "PRODUCT" ? hot.product : hot.video_url;

          const tempFn = () => {
            if (status === "NEW") {
              setHotspotLearningStatus.mutate(
                {
                  areaId: threeSixtyData._id,
                  hotspotId: hot._id,
                  status: "IN_PROGRESS",
                  areasProgress: areasProgress,
                  pointEvent: {
                    event: `hotspot_${hot._id}_clicked`,
                    points: 2,
                  },
                },
                {
                  onSuccess: () => {
                    refetchProgress();
                  },
                }
              );
            }

            setCurrentHotspotId(hot._id);
            let displayType: ModalDisplayTypes = dataType;
            dispatch({
              type: "SET_DATA",
              payload: {
                dataType: dataType,
                dataId: dataId,
              },
            });

            if (hot.questions && hot.questions.length > 0) {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: hot.questions,
                },
              });
            } else {
              dispatch({
                type: "SET_MODULE",
                payload: {
                  questions: undefined,
                },
              });
            }

            if (status === "COMPLETED") {
              displayType = "LEARNING_COMPLETE";
            } else if (status === "PASSED") {
              displayType = "MODULE_COMPLETE";
            }

            dispatch({
              type: "SET_DISPLAY_TYPE",
              payload: {
                displayType: displayType,
              },
            });
            setShowModal(true);
            sendEvent({
              event: "hotspot_clicked",
              parameters: {
                type: dataType,
                hotspot_id: hot._id,
              },
            });
          };
          hotspot.onClick = () => {
            executeFunctionNow(tempFn);
          };
        }
        return hotspot;
      })
      : [];
  }, [
    threeSixtyData.hotspots,
    threeSixtyData._id,
    threeSixtyData?.camera_initial_position,
    progress,
    getStatusIcon,
    areas,
    reduxDispatch,
    sendEvent,
    executeFunctionNow,
    setHotspotLearningStatus,
    areasProgress,
    refetchProgress,
    audioManager,
  ]);

  const handleLearningComplete = () => {
    if (progress && getHotspotStatus(currentHotspotId, progress) === "PASSED") {
      setShowModal(false);
      if (areasToShowMenu.includes(threeSixtyData.name)) {
        reduxDispatch(hideAreas());
        reduxDispatch(showNavigationModal());
        stopAudio();
        setTimeout(() => {
          if (threeSixtyData?.audio?.exit) {
            startAudio(threeSixtyData.audio.exit);
          }
        }, 50);
      }
      return;
    }
    sendEvent({
      event: "hotspot_learning_completed",
      parameters: {
        hotspot_id: currentHotspotId,
      },
    });
    if (state.questions) {
      setHotspotLearningStatus.mutate({
        areaId: threeSixtyData._id,
        hotspotId: currentHotspotId,
        status: "COMPLETED",
        areasProgress: areasProgress,
      });
      dispatch({
        type: "SET_DISPLAY_TYPE",
        payload: {
          displayType: "MODULE",
        },
      });
    } else {
      onHotspotComplete("PASSED");
    }
  };

  const learnProductAgain = () => {
    let hotspot = hotspots.find((o) => o._id === currentHotspotId);

    if (hotspot.on_click_event === "PRESENTATION") {
      setShowPresentation(true);
      sphericalNewRef.current?.triggerZoomIn({
        fovDiff: presentationZoomValue,
        cameraPosition: threeSixtyData?.camera_initial_position,
      });
      const transitionVideoData = { ...presentationDataJson }[currentHotspotId]
        ?.transitionVideo;
      if (transitionVideoData && sphericalNewRef.current) {
        const { parameters } = transitionVideoData;
        sphericalNewRef.current.play2dVideo({
          videos: [{ ...parameters }],
          onVideoStart: async () => {
            sphericalNewRef?.current?.resetCamera({ resetFov: true });
            const data = { ...presentationDataJson }[currentHotspotId];
            const transitionAudioData = data?.audios;
            const tempImage = data?.sphericalImageUrl;
            setShowModal(false);
            console.log(tempImage, "temp image learn again");

            setDisablePresentationButtons(true);
            setTempSphericalImage(tempImage ? tempImage : "");
            if (transitionAudioData && transitionAudioData.length > 0) {
              for (let audio of transitionAudioData) {
                audioManager.startAudio(audio.url);
                audioManager.executeFunctionLater(() => {
                  setDisablePresentationButtons(false);
                });
              }
            }
          },
          onVideoEnd: () => {
            sphericalNewRef?.current?.enableCameraRotation();
            if (audioManager.isAudioElementMuted) {
              setDisablePresentationButtons(false);
            }
          },
        });
      }
    } else {
      dispatch({
        type: "SET_DISPLAY_TYPE",
        payload: {
          displayType: state.dataType,
        },
      });
    }
  };

  const takeAssessmentAgain = () => {
    dispatch({
      type: "SET_DISPLAY_TYPE",
      payload: {
        displayType: "MODULE",
      },
    });
  };

  const bgImage =
    threeSixtyData.name === "Exterior"
      ? exteriorStillImage
      : threeSixtyData.name === "Living Area"
        ? livingStillImage
        : farmStillImage;
  const onClosePresentationOverlay = () => {
    setShowPresentation(false);
    setTempSphericalImage("");
    sphericalNewRef.current?.triggerZoomOut({
      fovDiff: presentationZoomValue,
      cameraPosition: threeSixtyData.camera_initial_position,
    });
    if (progress) {
      const learningStatus = getHotspotStatus(currentHotspotId, progress);
      // const assessmentStatus = progress.assessment_progress.find(
      //   (h: any) => h.hotspot === currentHotspotId
      // )?.status;
      // console.log(learningStatus, assessmentStatus, "assessment status");

      let displayType: ModalDisplayTypes = "LEARNING_COMPLETE";
      if (learningStatus === "COMPLETED") {
        displayType = "LEARNING_COMPLETE";
      } else if (learningStatus === "PASSED") {
        displayType = "MODULE_COMPLETE";
      }

      if (learningStatus === "IN_PROGRESS") {
        setHotspotLearningStatus.mutate(
          {
            areaId: threeSixtyData._id,
            hotspotId: currentHotspotId,
            status: "COMPLETED",
            areasProgress: areasProgress,
            pointEvent: {
              event: `hotspot_${currentHotspotId}_clicked`,
              points: 2,
            },
          },
          {
            onSuccess: () => {
              refetchProgress();
            },
          }
        );
      }
      dispatch({
        type: "SET_DISPLAY_TYPE",
        payload: {
          displayType: displayType,
        },
      });
      setShowModal(true);
    }
  };

  const onSphericalViewerMount = useCallback(() => {
    if (areas.length > 0 && sphericalNewRef.current?.preloadImages) {
      const preloadImagesArray = areas.map(
        (area: AreaDefinition) => area.spherical_image_url
      );
      sphericalNewRef.current.preloadImages(
        preloadImagesArray,
        () => {
          setIsPreloadingAreas(false);
        },
        (prog) => { }
      );
    }
  }, [areas]);

  return (
    <>
      {viewerType === "new" && (
        <div
          className={`position-fixed w-100 h-100 bg-white ${isPreloadingAreas ? "d-flex" : "d-none"
            }`}
          style={{ zIndex: 100 }}
        >
          <LandingScreenAnim />
        </div>
      )}
      <div style={{ width: "100%", height: "100%" }}>
        {showPresentation && (
          <div className="position-relative">
            <PresentationOverlay
              sphericalNewRef={sphericalNewRef.current}
              hotspotId={currentHotspotId}
              onClose={onClosePresentationOverlay}
              shouldDisableFeatureButtons={disablePresentationButtons}
              prevAreaData={areaData}
            />
          </div>
        )}
        {!threeSixtyData.is_sub_area_selector && (
          <>
            {showAnimation && (
              <ExteriorAnimation
                area={
                  threeSixtyData.name === "Exterior"
                    ? "EXTERIOR"
                    : threeSixtyData.name === "Living Area"
                      ? "LIVING"
                      : "FARM"
                }
                imageUrl={bgImage}
                hideAnimation={() => {
                  setShowAnimation(false);
                }}
              />
            )}
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `${areasToShowAnimation.includes(threeSixtyData.name)
                  ? `url(${bgImage})`
                  : ""
                  }`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: showAnimation ? 0 : 1, //causes black screen flickering on area change if mounted and unmounted
              }}
            >
              {!showAnimation && (
                <>
                  {viewerType === "new" ? (
                    <div
                      ref={onSphericalViewerMount}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <SphericalViewerNew
                        ref={sphericalNewRef}
                        sphericalImagePath={
                          tempSphericalImage === ""
                            ? threeSixtyData.spherical_image_url
                            : tempSphericalImage
                        }
                        radius={threeSixtyData.radius}
                        sphericalImageType={threeSixtyData.spherical_image_type}
                        cameraInitialPosition={
                          threeSixtyData.camera_initial_position
                        }
                        hotspots={showPresentation ? [] : hotspots}
                        fov={threeSixtyData.fov}
                        autoRotate={areasToAutoRotate.includes(
                          threeSixtyData.name
                        )}
                      // rotateSpeed={0.05}
                      />
                    </div>
                  ) : (
                    <SphericalViewer
                      ref={sphericalRef}
                      sphericalImagePath={threeSixtyData.spherical_image_url}
                      radius={threeSixtyData.radius}
                      sphericalImageType={threeSixtyData.spherical_image_type}
                      cameraInitialPosition={
                        threeSixtyData.camera_initial_position
                      }
                      hotspots={hotspots}
                      fov={threeSixtyData.fov}
                      loadingComponent={
                        areasToShowAnimation.includes(threeSixtyData.name) ? (
                          <SphericalLoading img={threeSixtyData.thumbnail} />
                        ) : undefined
                      }
                      autoRotate={areasToAutoRotate.includes(
                        threeSixtyData.name
                      )}
                    />
                  )}
                </>
              )}
            </div>
            {/* )} */}

            {/* {final ? <div style={{"position":"absolute","bottom":"100" }}>
                   <button onClick={finalQuestionButtonClick} >view final questions</button>
                  </div> : null} */}
          </>
        )}
        {threeSixtyData.is_sub_area_selector && threeSixtyData.sub_areas && (
          <AreaPopup
            popupData={threeSixtyData.sub_areas}
            backgroundImage={threeSixtyData.thumbnail}
            areas={areas}
          />
        )}
      </div>
      {showModal && (
        <ThreeSixtyModal
          show={showModal}
          areaId={areaData._id}
          setShow={setShowModal}
          modalType={state.displayType}
          productId={state.dataId}
          videoUrl={state.dataId}
          questions={state.questions}
          learnProductAgain={learnProductAgain}
          takeAssessmentAgain={takeAssessmentAgain}
          onAssessmentComplete={onHotspotComplete}
          handleLearningComplete={handleLearningComplete}
          playExitAudio={playExitAudio}
          playEntryAudio={playEntryAudio}
          hotspotName={getHotspotName(currentHotspotId, threeSixtyData)}
        />
      )}
      {showCourseCompletedModal && (
        <CourseCompletedModal show={showCourseCompletedModal} />
      )}
      {
        // showCommonModal &&(
        <CommonModal
          show={showCommonModal}
          setShow={setShowCommonModal}
          modalType="VIDEO"
          videoUrl={areaVideoUrl}
        />
        // )
      }
    </>
  );
};

export default ThreeSixty;
