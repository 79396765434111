import { User } from "../types";

export const getUserFromLocalStorage = (viewerId: string): User | null => {
  const x = localStorage.getItem(`user_${viewerId}`);
  return x ? JSON.parse(x) : null;
};

export const saveUserToLocalStorage = (viewerId: string, user: User) => {
  return localStorage.setItem(`user_${viewerId}`, JSON.stringify(user));
};

export const removeUserFromLocalStorage = (viewerId: string) => {
  return localStorage.removeItem(`user_${viewerId}`);
};
