import { ViewerDefinition } from "../api";
import { addAuthHeaderToAxios } from "../api/client";
import { getUserFromLocalStorage } from "../utils/localstorage";
import { storedUser } from "../consts/user";

export const useUser = (viewerData: ViewerDefinition | undefined) => {
  if (viewerData?.access_details?.authentication_enabled) {
    const user = viewerData ? getUserFromLocalStorage(viewerData._id) : null;
    if (user && user.access_token) {
      addAuthHeaderToAxios(`Token ${user.access_token}`);
    }
    return {
      user: viewerData ? user : null,
    };
  } else {
    addAuthHeaderToAxios(`Token ${storedUser.access_token}`);
    return {
      user: viewerData ? storedUser : null,
    };
  }
};
