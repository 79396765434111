import PNFSrc from "../../assets/images/page_not_found.svg";

function PageNotFound() {
  return (
    <>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <img src={PNFSrc} alt="" style={{ height: "100vh", width: "100%" }} />
      </div>
    </>
  );
}

export default PageNotFound;
