import React, { useEffect, useState } from "react";
import { Button, ProgressBar, Row, Col } from "react-bootstrap";
import { QuestionDefinition } from "../../api";
import styles from "./LearningModule.module.css";
import Question from "./Question";
interface IProps {
  questions: QuestionDefinition[];
  onAssessmentComplete: (result: "PASSED" | "FAILED") => void;
  hotspotName: string;
}

const LearningModule: React.FC<IProps> = ({
  questions,
  onAssessmentComplete,
  hotspotName,
}) => {
  const numOfQuestions = questions.length ?? -1;
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [currentNum, setCurrentNum] = useState(0);
  const currentQuestion = questions[currentNum];
  const [answer, setAnswer] = useState(-1);
  const [isAssessmentComplete, setIsAssessmentComplete] = useState(false);
  useEffect(() => {
    if (currentNum === numOfQuestions) {
      setIsAssessmentComplete(true);
    }
  }, [currentNum, correctAnswers, numOfQuestions, onAssessmentComplete]);

  const handleSubmit = () => {
    if (!currentQuestion) {
      setAnswer(-1);
      return;
    }
    if (answer === -1) {
      alert("Please select an answer");
      return;
    }
    const correctAnswer = currentQuestion.correct_answer;
    if (correctAnswer === answer) {
      setCorrectAnswers((prev) => prev + 1);
    }
    setCurrentNum(currentNum + 1);
    setAnswer(-1);
  };
  return (
    <>
      {module && (
        <>
          {!isAssessmentComplete && currentQuestion && (
            <div className={styles.container}>
              <ProgressBar
                striped
                variant="info"
                now={(currentNum / numOfQuestions) * 100}
              />
              <h3 className="mt-5">{hotspotName}</h3>
              <div className="my-5">
                <Question
                  questionNumber={currentNum + 1}
                  question={currentQuestion.question}
                  options={currentQuestion.options}
                  setAnswer={setAnswer}
                />
              </div>
              <Button variant="outline-secondary" onClick={handleSubmit}>
                Next
              </Button>
            </div>
          )}
          {isAssessmentComplete && (
            <Row className="mt-5">
              <Col
                className="d-flex justify-content-center align-items-center"
                xs={12}
              >
                <h3>Result</h3>
              </Col>
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center my-5"
              >
                <h5>
                  You scored: {correctAnswers}/{numOfQuestions}
                </h5>
              </Col>
              <Col xs={12} md={{ span: 4, offset: 4 }}>
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    const percCorrectAnswers = (correctAnswers / numOfQuestions) * 100
                    const passingPerc = numOfQuestions > 2 ? 50 : 60

                    onAssessmentComplete(
                      percCorrectAnswers >= passingPerc ? "PASSED" : "FAILED"
                    );
                  }}
                  style={{ width: "100%" }}
                >
                  Finish
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default LearningModule;
