import { Col, Modal, Row } from "react-bootstrap";
import styles from "./CourseCompletedModal.module.css";

interface IProps {
  show: boolean;
}

const ThreeSixtyModal: React.FC<IProps> = ({ show }) => {
  const onClose = () => {};

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
    >
      <Modal.Body>
        <Row className="h-100">
          <Col xs={12} className={styles.contentContainer}>
            <h2 className={styles.mainText}>
              Congratulations! <br />
              You have completed the course
            </h2>
            <h5 className={styles.subText}>
              Your certificate is being generated and will be sent to your email
              address
            </h5>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ThreeSixtyModal;
