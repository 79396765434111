import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import progressReducer from "./slices/progressSlice";
import threeSixtyViewerReducer from "./slices/threeSixtyViewerSlice";
import navigationReducer from "./slices/navigationSlice";

const combinedReducer = combineReducers({
  threeSixtyViewer: threeSixtyViewerReducer,
  progress: progressReducer,
  navigation: navigationReducer,
});

const rootReducer: Reducer = (state: RootState, action: any) => {
  if (action.type === "RESET_REDUX") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
