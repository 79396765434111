import { axiosClient } from "./client";
import { AxiosRequestConfig } from "axios";
import {
  LoginSuccessDefinition,
  SendOtpDataType,
  VerifyOtpDataType,
} from "../api/types";

export const sendOtp = async ({ data }: { data: SendOtpDataType }) => {
  const config: AxiosRequestConfig = {
    url: "/auth/send_otp",
    method: "post",
    data: data,
  };
  const res = await axiosClient.request(config);
  return res.data.request_id;
};

export const verifyOtp = async ({ data }: { data: VerifyOtpDataType }) => {
  const config: AxiosRequestConfig = {
    url: "/auth/verify_otp",
    method: "post",
    data: data,
  };
  const res = await axiosClient.request<LoginSuccessDefinition>(config);
  return res.data;
};

export const logout = async () => {
  try {
    const config: AxiosRequestConfig = {
      url: "/auth/logout",
      method: "post",
    };
    await axiosClient.request(config);
    return true;
  } catch (err) {
    //this is a no-op
    console.error("Server failed to logout");
  }
};

export const verifyFirebaseToken = async (
  firebaseIdToken: string,
  applicationId?: string,
  viewerId?: string
) => {
  const config: AxiosRequestConfig = {
    url: "/firebase/verify_token",
    method: "post",
    data: {
      firebase_id_token: firebaseIdToken,
      ...(applicationId && { application: applicationId }),
      ...(viewerId && { viewer: viewerId }),
    },
  };
  const res = await axiosClient.request<LoginSuccessDefinition>(config);
  return res.data;
};
