import {
  AreaDefinition,
  AreaProgressDefinition,
  AssessmentStatus,
  LearningStatus,
} from "../api";
import { ReduxUserProgressDefinition } from "../store/slices/progressSlice";

export const findAreaById = (areas: AreaDefinition[], areaId?: string) => {
  if (!areaId) {
    return undefined;
  }
  const area = areas.find((a) => a._id === areaId);
  return area;
};

export const findAreaProgress = (
  areasProgress: ReduxUserProgressDefinition[],
  areaId: string
) => {
  const areaProgress = areasProgress.find((ap) => ap.area === areaId);
  return areaProgress;
};

export const checkIfAreaCompleted = (
  areaProgress: AreaProgressDefinition,
  area: AreaDefinition
): boolean => {
  return true;
};

export const getCombinedHotspotStatus = (
  learningStatus?: LearningStatus,
  assessmentStatus?: AssessmentStatus
) => {
  if (!learningStatus) {
    return "NEW";
  }
  if (learningStatus === "COMPLETED") {
    switch (assessmentStatus) {
      case "NEW":
        return "COMPLETED";
      case "PASSED":
        return assessmentStatus;
      case "FAILED":
        return assessmentStatus;
      default:
        return "COMPLETED";
    }
  }
  return learningStatus;
};

export const getPointsFromMap = (
  areaPointsMap: { [key: string]: number } | undefined
) => {
  let points = 0;
  if (areaPointsMap) {
    Object.keys(areaPointsMap).forEach((key) => {
      points += areaPointsMap[key];
    });
  }
  return points;
};
