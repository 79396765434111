import { useMutation } from "react-query";
import { patchUserLearningProgress, UserProgressDefinition } from "../api";
import { useAppSelector } from "../store/hooks";

interface PointsMutation {
  progress: UserProgressDefinition;
  areaId: string;
  event: string;
  points: number;
}

export const useAreaPointsMutation = () => {
  const { userProgressId } = useAppSelector((state) => state.progress);
  const mutation = useMutation<string, any, PointsMutation>((data) => {
    const areaProgress = data?.progress?.area_progress
      ? [...data.progress.area_progress]
      : [];
    const currentAreaIndex = areaProgress.findIndex(
      (ap) => ap.area === data.areaId
    );
    const updatedProgress = [...areaProgress];
    if (currentAreaIndex >= 0) {
      const currentAreaProgress = { ...updatedProgress[currentAreaIndex] };
      let points_map: { [key: string]: number } = {};
      if (currentAreaProgress.area_points_map) {
        points_map = { ...currentAreaProgress.area_points_map };
      }
      points_map[data.event] = data.points;
      currentAreaProgress.area_points_map = points_map;
      updatedProgress[currentAreaIndex] = currentAreaProgress;
    }
    return patchUserLearningProgress(userProgressId, {
      area_progress: updatedProgress,
    });
  });

  return mutation;
};
