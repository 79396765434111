import { AxiosRequestConfig } from "axios";
import { axiosClient } from "./client";
import { SessionEventDefinition } from "./types";

export const postSessionEvent = async (eventData: SessionEventDefinition) => {
  try {
    const config: AxiosRequestConfig = {
      url: "app/session/event",
      method: "post",
      data: eventData,
    };
    await axiosClient.request(config);
    return true;
  } catch (err: any) {
    //TODO: log error to server
    throw err;
  }
};
