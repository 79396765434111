export const storedUser = {
  access_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTkwODk3MDcsImFjY291bnRfaWQiOiI2MWJhZWE5ZjczNmQ4Yzk5NjBiZjk2NWEiLCJzZXNzaW9uX2lkIjoiNjJlM2IzMmJhYTk3OWQ3NTM2YzEwYmJlIiwidXNlcl9uYW1lIjoic2FuZGVlcEBoZWxsb2FyLmlvIn0.fcehRPVX_90SFPLiFmSAAJYdhANmubjlbpczYh1iiRk",
  session_id: "62e3b32baa979d7536c10bbe",
  user_name: "sandeep@helloar.io",
  first_name: "Sandeep",
  last_name: "G",
  is_super_admin: null,
  account_id: "61baea9f736d8c9960bf965a",
};
