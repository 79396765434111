import { useQuery } from "react-query";
import { getViewer, ViewerDefinition } from "../api";

export const useViewer = (viewerId: string) => {
  const queryKeys = ["viewers", viewerId];
  return useQuery<ViewerDefinition>(
    queryKeys,
    async () => {
      
      return await getViewer(viewerId);
    },
    {
      enabled: viewerId.length > 0,
    }
  );
};
