import React, { useState } from "react";
import presentationDataJson from "../../consts/presentation.json";
import styles from "./PresentationOverlay.module.css";
import closeIcon from "../../assets/icons/close.svg";
import featurePlaceholderIcon from "../../assets/icons/feature.png";
import { Col, Image } from "react-bootstrap";
import { SphericalViewerNewRefType } from "hello-react-sdk-v2/dist/types";
import { useOutletContext } from "react-router-dom";
import { OutletContextDefinition } from "../../types";
import playButton from "../../assets/images/play.png";
import muteButton from "../../assets/images/mute.png";
import { AreaDefinition } from "../../api";
export interface PresentationFeatureType {
  icon: string;
  title: string;
  buttonText: string;
  description: string;
  actions?: ActionType[];
  audios?: AudioType[];
}
interface AudioType {
  url: string;
  text: string;
}
interface ActionType {
  type: string;
  parameters: {
    videoOptions: {
      url: string;
      loop?: boolean;
      loopCount?: number;
      showLastFrame?: boolean;
    };
    width: number;
    height: number;
    position: {
      x: number;
      y: number;
    };
  };
}

interface IProps {
  sphericalNewRef: SphericalViewerNewRefType | null;
  hotspotId: string;
  onClose: () => void;
  shouldDisableFeatureButtons: boolean;
  prevAreaData: AreaDefinition;
}

const PresentationOverlay: React.FC<IProps> = ({
  sphericalNewRef,
  hotspotId,
  onClose,
  shouldDisableFeatureButtons,
  prevAreaData,
}) => {
  const { audioManager } = useOutletContext<OutletContextDefinition>();
  const {
    state: audioPlayerState,
    stopAudio,
    startAudioArray,
    onAudioButtonClicked,
    setAudioSrc,
    pauseAudio,
  } = audioManager;
  const data = { ...presentationDataJson }[hotspotId];
  const transitionAudioUrl = data?.audios[0].url;
  const [headerTitle, setHeaderTitle] = useState<string>(
    data?.name || "Select Feature"
  );
  const [viewingThreeDee, setViewingThreeDee] = useState(false);

  const [currentAudioText, setCurrentAudioText] = useState("");

  const [featuresChecked, setFeaturesChecked] = useState<string[]>([]);

  const onAudioClick = async () => {
    onAudioButtonClicked();
    if (currentAudioText) {
      setCurrentAudioText("");
    }
  };

  const onFeatureEnd = (featureTitle?: string) => {
    setCurrentAudioText("");
    setAudioSrc(transitionAudioUrl ?? "");
    if (featureTitle) {
      addFeatureToChecked(featureTitle);
    }
  };

  const changeAudioText = (audioIndex: number, featureIndex: number) => {
    const allFeatures = data?.features ?? [];
    if (allFeatures.length === 0) {
      alert("No Features");
      return;
    }
    const requiredFeature = allFeatures[featureIndex];
    const requiredFeatureAudios = requiredFeature.audios;
    const currentAudio = requiredFeatureAudios[audioIndex];
    setCurrentAudioText(currentAudio.text);
  };

  const addFeatureToChecked = (featureTitle: string) => {
    if (!featuresChecked.includes(featureTitle)) {
      const tempCheckedFeatures = [...featuresChecked];
      tempCheckedFeatures.push(featureTitle);
      setFeaturesChecked(tempCheckedFeatures);
    }
  };

  const onFeatureButtonClick = async (featureIndex: number) => {
    const allFeatures = data?.features ?? [];
    if (allFeatures.length === 0) {
      alert("No Features");
      return;
    }
    setCurrentAudioText("");
    const requiredFeature = allFeatures[featureIndex];
    const requiredFeatureActions = requiredFeature.actions;
    const requiredFeatureAudios = requiredFeature.audios;
    if (requiredFeature.title === headerTitle) {
      alert("Already viewing this feature");
      return;
    }
    setHeaderTitle(requiredFeature.title);
    audioManager.stopAudio();
    sphericalNewRef?.stopVideo();
    const mappedRequiredAudios = requiredFeatureAudios.map((audio) => ({
      url: audio.url,
    }));
    if (requiredFeatureActions && requiredFeatureActions.length > 0) {
      const action = requiredFeatureActions[0];
      switch (action.type) {
        case "2DVIDEO":
          const videos = requiredFeatureActions.map((act: ActionType) => {
            return act.parameters;
          });
          await sphericalNewRef?.play2dVideoAsync({
            videos,
            onVideoStart: () => {
              startAudioArray(
                mappedRequiredAudios,
                (audioIndex: number) => {
                  changeAudioText(audioIndex, featureIndex);
                },
                () => {
                  onFeatureEnd(requiredFeature.title);
                }
              );
            },
          });

          return;

        default:
          console.log(action);
          return;
      }
    } else {
      startAudioArray(
        mappedRequiredAudios,
        (audioIndex: number) => changeAudioText(audioIndex, featureIndex),
        () => {
          onFeatureEnd(requiredFeature.title);
        }
      );
    }
  };

  const onPresentationEnded = () => {
    console.log("Presentation ended");
    stopAudio();
    sphericalNewRef?.stopVideo();
    const prevAreaAudio = prevAreaData.audio?.entry ?? "";
    if (prevAreaAudio) {
      setAudioSrc(prevAreaAudio);
      pauseAudio();
    }
  };

  const onCloseClick = () => {
    onClose();
    setFeaturesChecked([]);
    onPresentationEnded();
  };

  if (!data) {
    return (
      <div className={styles.container} style={{ zIndex: "100" }}>
        <div className=" bg-white">data for this hotspot id not found</div>
      </div>
    );
  }
  if (!hotspotId) {
    return (
      <div className={styles.container} style={{ zIndex: "100" }}>
        Loading...
      </div>
    );
  }
  return (
    <div className={`${styles.container} `}>
      <div className={styles.topBar}>
        <h4>{headerTitle}</h4>
        <div className="d-flex flex-row justify-items-center align-items-center">
          <button onClick={onAudioClick} className={styles.muteButton}>
            <Image
              className={styles.audioButton}
              src={audioPlayerState === "STARTED" ? playButton : muteButton}
            />
          </button>
          <button onClick={onCloseClick} className={styles.closeButton}>
            <img src={closeIcon} width="30" alt="close" />
          </button>
        </div>
      </div>
      {viewingThreeDee && (
        <div className={styles.threeDeeViewerModal}>
          <div className="w-75 h-75 position-relative">
            <div className={styles.viewerContainer}>
              <button
                onClick={() => {
                  setViewingThreeDee(false);
                }}
                className="position-absolute ms-2 mt-2 border-0 bg-transparent"
              >
                <img src={closeIcon} alt="feature-icon" width={30} />
              </button>
              <iframe
                className="w-100 h-100"
                src={
                  "https://viewer.helloar.io/?transparent=true&id=" + data.pid
                }
                allow="xr-spatial-tracking;fullscreen;"
                title="viewer"
              ></iframe>
            </div>
          </div>
        </div>
      )}
      {!viewingThreeDee && (
        <div className={styles.threeDeeButton}>
          {data.pid && (
            <button
              onClick={() => {
                setViewingThreeDee((prev) => {
                  console.log(!prev);
                  return !prev;
                });
              }}
              className={`${styles.featureButton}
            } bg-white`}
            >
              <img src="/assets/icons/cube.png" alt="feature-icon" width={20} />
              <span className={`my-0 h6 ms-2 ${styles.featureButtonText}`}>
                View in 3D
              </span>
            </button>
          )}
        </div>
      )}
      <div className={styles.buttonsContainerParent}>
        <Col xs={3} sm={4} lg={2} className={styles.buttonsContainer}>
          {data?.features?.length > 0 &&
            data.features.map((feature, index) => (
              <button
                key={index}
                onClick={() => onFeatureButtonClick(index)}
                className={`${styles.featureButton} ${
                  headerTitle === feature.title && "bg-white"
                } position-relative ${
                  featuresChecked.includes(feature.title) &&
                  styles.featureButtonChecked
                }`}
                disabled={shouldDisableFeatureButtons || feature.disabled}
              >
                {featuresChecked.includes(feature.title) && (
                  <div
                    className="position-absolute px-2 py-2 rounded"
                    style={{
                      right: "-15%",
                      background: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    <img
                      src="/assets/icons/checkedIcon.png"
                      alt="feature-icon"
                      className=""
                      width={20}
                    />
                  </div>
                )}
                <img
                  src={feature.icon || featurePlaceholderIcon}
                  alt="feature-icon"
                  width={40}
                />
                <span className={`my-0 ms-3 h6 ${styles.featureButtonText}`}>
                  {feature.buttonText}
                </span>
              </button>
            ))}
        </Col>
      </div>

      <Col
        xs={6}
        lg={8}
        className={`${styles.audioTextContainerInactive} px-4 py-3 h4 ${
          currentAudioText ? styles.audioTextContainerActive : ""
        }`}
      >
        {currentAudioText.split("\n").map((textLine: string, index) => (
          <p key={index}>{textLine}</p>
        ))}
      </Col>
    </div>
  );
};

export default PresentationOverlay;
