import { AxiosRequestConfig } from "axios";
import { axiosClient } from "./client";
import { ViewerDefinition } from "./types";

export const getViewer = async (viewerId: string) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/viewers/${viewerId}`,
      method: "get",
    };
    const response = await axiosClient.request<ViewerDefinition>(config);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 404) {
      throw new Error("This viewer doesn't exist");
    }
    throw err?.response?.data?._issues
      ? err.response.data._issues
      : "Couldn't fetch viewer :( Please try again later.";
  }
};
