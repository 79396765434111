import { useMutation, useQuery } from "react-query";
import {
  AreaDefinition,
  AssessmentStatus,
  getUserLearningProgress,
  LearningStatus,
  patchCompletionCertificate,
  patchUserLearningProgress,
  postUserLearningProgress,
  UserProgressDefinition,
} from "../api";
import { generateCertificate } from "../api/certificate";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  ReduxUserProgressDefinition,
  setAssessmentStatus,
  setLearningStatus,
  setProgress,
  setUserProgressId,
} from "../store/slices/progressSlice";
import { setThreeSixtyData } from "../store/slices/threeSixtyViewerSlice";

interface UpdateHotspotLearning {
  areasProgress: ReduxUserProgressDefinition[];
  areaId: string;
  hotspotId: string;
  status: LearningStatus;
  pointEvent?: {
    event: string;
    points: number;
  };
}

interface UpdateHotspotAssessment {
  areasProgress: ReduxUserProgressDefinition[];
  areaId: string;
  hotspotId: string;
  status: AssessmentStatus;
  total_questions: number;
  num_of_questions_completed: number;
  pointEvent?: {
    event: string;
    points: number;
  };
}

export const useProgress = (
  viewerId: string,
  userId: string,
  areas: AreaDefinition[]
) => {
  const { areaData } = useAppSelector((state) => state.threeSixtyViewer);
  const dispatch = useAppDispatch();
  return useQuery<UserProgressDefinition>(
    "user_progress",
    async () => {
      let progress: UserProgressDefinition | undefined =
        await getUserLearningProgress(userId, viewerId);
      if (!progress) {
        const progressId = await postUserLearningProgress(userId, viewerId);
        progress = {
          _id: progressId,
          account: userId,
          viewer: viewerId,
          area_progress: [],
        };
      }
      if (!progress.area_progress) {
        progress.area_progress = [];
      }
      const areasProgress = progress.area_progress.map((ap) => {
        return {
          ...ap,
          is_area_completed: false,
        };
      });
      let initialArea: AreaDefinition | null = null;
      let isCourseCompleted = true;
      areas.forEach((area, areaIndex) => {
        let isAreaAutoCompleted = false;
        let areaProgress = areasProgress.find((ap) => ap.area === area._id);
        if (!areaProgress) {
          areasProgress.push({
            area: area._id,
            is_area_completed: false,
            learning_progress: [],
            final_progress: "",
            assessment_progress: [],
          });
          areaProgress = areasProgress.find((ap) => ap.area === area._id);
        }
        if (!areaProgress) {
          throw new Error("Unknown Error");
        }
        if (!areaProgress.learning_progress) {
          areaProgress.learning_progress = [];
        }
        if (!areaProgress.assessment_progress) {
          areaProgress.assessment_progress = [];
        }
        if (!areaProgress?.final_progress) {
          areaProgress.final_progress = "";
        }
        let isAreaComplete = true;
        if (area.hotspots) {
          for (let i = 0; i < area.hotspots?.length; i++) {
            const hotspot = area.hotspots[i];
            const hotspotId = hotspot._id;
            const learningForHotspot = areaProgress.learning_progress.find(
              (lp) => lp.hotspot === hotspotId
            );
            if (!learningForHotspot) {
              areaProgress.learning_progress.push({
                hotspot: hotspotId,
                status:
                  hotspot.on_click_event === "NAVIGATION" ? "COMPLETED" : "NEW",
              });
            }

            const assessmentForHotspot = areaProgress.assessment_progress.find(
              (lp) => lp.hotspot === hotspotId
            );
            if (!assessmentForHotspot) {
              areaProgress.assessment_progress.push({
                hotspot: hotspotId,
                status:
                  hotspot.on_click_event === "NAVIGATION" ? "PASSED" : "NEW",
                total_questions: hotspot.questions
                  ? hotspot.questions.length
                  : 0,
                num_of_questions_completed: 0,
              });
              if (hotspot.on_click_event !== "NAVIGATION") {
                isAreaComplete = false;
              } else {
                isAreaAutoCompleted = true;
              }
            } else {
              if (
                hotspot.on_click_event !== "NAVIGATION" &&
                assessmentForHotspot.status !== "PASSED"
              ) {
                isAreaComplete = false;
              }
            }
          }
        }
        if (area.is_sub_area_selector) {
          isAreaComplete = true;
          const children = area.sub_areas;
          children?.forEach((child) => {
            if (
              !areasProgress.find((a) => a.area === child.area)
                ?.is_area_completed
            ) {
              isAreaComplete = false;
            }
          });
        }

        areaProgress.is_area_completed = isAreaComplete;

        if (!initialArea) {
          if (isAreaComplete && isAreaAutoCompleted) {
            initialArea = area;
          } else if (!isAreaComplete) {
            initialArea = area;
          }
        }

        if (!isAreaComplete) {
          isCourseCompleted = false;
        }
      });
      if (!areaData) {
        if (isCourseCompleted) {
          initialArea = areas[areas.length - 1];
        }
        if (!initialArea) {
          initialArea = areas[0];
        }
        dispatch(setThreeSixtyData(initialArea));
      }
      dispatch(setUserProgressId(progress._id));
      dispatch(setProgress(areasProgress));

      return progress;
    },
    {
      enabled: viewerId.length > 0 && userId.length > 0 && areas.length > 0,
    }
  );
};

const patchLearningStatus = async (
  userProgressId: string,
  data: UpdateHotspotLearning
) => {
  try {
    const progressToPatch = data.areasProgress.map(
      ({ is_area_completed, ...rest }) => {
        let ap = { ...rest };
        if (ap.area === data.areaId) {
          const hotspotToUpdateIndex = ap.learning_progress.findIndex(
            (lp) => lp.hotspot === data.hotspotId
          );
          if (hotspotToUpdateIndex >= 0) {
            const hotspotToUpdate = {
              ...ap.learning_progress[hotspotToUpdateIndex],
            };
            hotspotToUpdate.status = data.status;
            const lpnew = ap.learning_progress.map(
              ({ started_at, completed_at, ...rest }) => {
                return rest;
              }
            );
            lpnew[hotspotToUpdateIndex] = hotspotToUpdate;
            ap.learning_progress = lpnew;
          } else {
            ap.learning_progress.push({
              hotspot: data.hotspotId,
              status: data.status,
            });
          }

          if (data.pointEvent) {
            ap.area_points_map = { ...ap.area_points_map } ?? {};
            ap.area_points_map[data.pointEvent.event] = data.pointEvent.points;
          }
        }
        return ap;
      }
    );
    await patchUserLearningProgress(userProgressId, {
      area_progress: progressToPatch,
    });
    return true;
  } catch (err) {
    console.error("Error while patching progress to server");
    throw err;
  }
};

const patchAssessmentStatus = async (
  userProgressId: string,
  data: UpdateHotspotAssessment
) => {
  try {
    const progressToPatch = data.areasProgress.map(
      ({ is_area_completed, ...rest }) => {
        const ap = { ...rest };
        if (ap.area === data.areaId) {
          const hotspotToUpdateIndex = ap.assessment_progress.findIndex(
            (lp) => lp.hotspot === data.hotspotId
          );
          if (hotspotToUpdateIndex >= 0) {
            const hotspotToUpdate = {
              ...ap.assessment_progress[hotspotToUpdateIndex],
            };
            hotspotToUpdate.status = data.status;
            hotspotToUpdate.total_questions = data.total_questions;
            hotspotToUpdate.num_of_questions_completed =
              data.num_of_questions_completed;
            const apnew = ap.assessment_progress.map(
              ({ started_at, completed_at, ...rest }) => {
                return rest;
              }
            );
            apnew[hotspotToUpdateIndex] = hotspotToUpdate;
            ap.assessment_progress = apnew;
          } else {
            ap.assessment_progress.push({
              hotspot: data.hotspotId,
              status: data.status,
              total_questions: 0,
              num_of_questions_completed: 0,
            });
          }

          const hotspotToUpdateIndex2 = ap.learning_progress.findIndex(
            (lp) => lp.hotspot === data.hotspotId
          );
          if (hotspotToUpdateIndex2 >= 0) {
            const hotspotToUpdate2 = {
              ...ap.learning_progress[hotspotToUpdateIndex2],
            };
            hotspotToUpdate2.status = "COMPLETED";
            const lpnew = ap.learning_progress.map(
              ({ started_at, completed_at, ...rest }) => {
                return rest;
              }
            );
            lpnew[hotspotToUpdateIndex2] = hotspotToUpdate2;
            ap.learning_progress = lpnew;
          } else {
            ap.learning_progress.push({
              hotspot: data.hotspotId,
              status: "COMPLETED",
            });
          }
          if (data.pointEvent) {
            ap.area_points_map = { ...ap.area_points_map } ?? {};
            ap.area_points_map[data.pointEvent.event] = data.pointEvent.points;
          }
        }
        return ap;
      }
    );
    await patchUserLearningProgress(userProgressId, {
      area_progress: progressToPatch,
    });
    return true;
  } catch (err) {
    console.error("Error while patching progress to server");
    throw err;
  }
};

export const useUpdateProgress = () => {
  const { userProgressId } = useAppSelector((state) => state.progress);
  const dispatch = useAppDispatch();
  const setHotspotLearningStatus = useMutation<
    boolean,
    any,
    UpdateHotspotLearning
  >((data) => {
    dispatch(
      setLearningStatus({
        areaId: data.areaId,
        hotspotId: data.hotspotId,
        status: data.status,
      })
    );
    return patchLearningStatus(userProgressId, data);
  });

  const setHotspotAssessmentStatus = useMutation<
    boolean,
    any,
    UpdateHotspotAssessment
  >((data) => {
    dispatch(
      setLearningStatus({
        areaId: data.areaId,
        hotspotId: data.hotspotId,
        status: "COMPLETED",
      })
    );
    dispatch(
      setAssessmentStatus({
        areaId: data.areaId,
        hotspotId: data.hotspotId,
        status: data.status,
      })
    );

    return patchAssessmentStatus(userProgressId, data);
  });

  return {
    setHotspotLearningStatus,
    setHotspotAssessmentStatus,
  };
};

interface CertificateMutation {
  courseName: string;
  userName: string;
}

const generateAndPatchCertificate = async (
  userProgressId: string,
  data: CertificateMutation
) => {
  const certificateURL = await generateCertificate(
    data.userName,
    data.courseName
  );
  await patchCompletionCertificate(userProgressId, certificateURL);
};

export const useMutateCertificate = () => {
  const { userProgressId } = useAppSelector((state) => state.progress);
  const mutation = useMutation<void, any, CertificateMutation>((data) => {
    return generateAndPatchCertificate(userProgressId, data);
  });

  return mutation;
};
